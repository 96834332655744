import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logout from "./Logout";
import { useDispatch, useSelector } from "react-redux";
import { Role } from "../../config/config";
import { setLoggedIn, setUser, setUserRoleId } from "../../store/userRoleSlice";
const img_url = "../../../../images/";

const Navbar = () => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch()
  const location = useLocation();
  const [isUserLoggedIn, _isUserLoggedIn] = useState(false);
  const [role, _role] = useState();
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const darkPaths = ['/tournamentManagement', '/liveScore', '/certificateGenration', '/bibManagement', '/auctionBay', '/creativeMaking', '/contactUsPage'];

  const { userRoleId, isLoggedIn } = useSelector((state) => state.userRoleId)

  const navChanges = () => {
    return location.pathname === '/';
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      _isUserLoggedIn(true);
      if (!isLoggedIn) {
        dispatch(setLoggedIn(true));
        dispatch(setUser(JSON.parse(localStorage.getItem("user"))));
      }
      return;
    }
    _isUserLoggedIn(false);
  }, [isLoggedIn, userDetails]);


  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    if (userRole) {
      if (!userRoleId) {
        dispatch(setUserRoleId(userRole));
      }
      _role(userRole);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [userRoleId]);

  // const navigate = (type) => {
  //   if (type === 'suite') {
  //     window.open('https://qasuite.zemo.co.in/#/?token=' + token, "_blank");
  //   }
  // }


  // while page scroll
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };


  // page scroll top when click on link
  const scrollToTop = (param) => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth" 
      // Optional: smooth scrolling behavior
    });
    setNavbarExpanded(false);
    if (!isUserLoggedIn && location.pathname.includes('my-booking') && param === "register") {
      localStorage.setItem('createdUrl', window.location.href);
    }
  };

  const handleMenuItemClick = (param) => {
    setNavbarExpanded(false);
    const itemsToRemove = [
      'sponsorMultiple',
      'seoDetail',
      'tournamentDetail',
      'thumbnail',
      'poster',
      'gallery',
      'youTubeUrl',
      'editTournament',
      'sportsDetails',
      'venuDetail',
      'currentAddingId',
      'setSidebarStatus',
      'facebooklink',
      'instagramlink',
      'twitterlink',
      'linkedInlink',
      'whatsAppgrouplink'
    ];
    itemsToRemove.forEach(item => localStorage.removeItem(item));

    scrollToTop()
    if (!isUserLoggedIn && location.pathname.includes('my-booking') && param === "login") {
      localStorage.setItem('createdUrl', window.location.href);
    }
  };

  return (
    <div className={`${isScrolled ? 'scrolled' : ''}`}>
      <nav
        className={navChanges() ? "homepage navbar navbar-expand-lg" : `innerpage navbar navbar-expand-lg ${darkPaths.includes(location.pathname) ? "black-backgroudnd" : ''}`} >
        <div className="container-fluid">
          <div className="logobackground">
            {/* nav items  */}
            <NavLink className="navbar-brand h-100  d-inline-block" to="/">
              <img src={img_url + "ZemoLogoNeon.svg"} alt="Zemo" />
            </NavLink>
          </div>
          <div className="headerButton">
            {isUserLoggedIn ?
              "" : <NavLink to="/login" className="btn btn-light login-btn mx-2">
                Login
              </NavLink>}
            <button
              className={`navbar-toggler ${navbarExpanded ? '' : 'collapsed'}`}
              type="button"
              onClick={() => setNavbarExpanded(!navbarExpanded)}
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={navbarExpanded ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <span class="icon-bar top-bar"></span>
              <span class="icon-bar middle-bar"></span>
              <span class="icon-bar bottom-bar"></span>
            </button>
          </div>
          <div className={`collapse navbar-collapse ${navbarExpanded ? 'show' : ''}`} id="navbarSupportedContent">
            <ul
              className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" onClick={handleMenuItemClick} aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <div
                className="nav-dropdown-container"
                onMouseEnter={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
              >
                <NavDropdown
                  title="Solutions"
                  id="basic-nav-dropdown"
                  show={dropdown}
                  className={location.pathname === '/featuresandPricing' || location.pathname === '/aboutZemo' ? 'active' : ''}
                >
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/tournamentManagement">
                      Tournament Management
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/liveScore">
                      Live Scoring
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/certificateGenration">
                      Certificate Generation
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/creativeMaking">
                      Creative Making
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/bibManagement">
                      Bib Management
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/auctionBay">
                      Auction Bay
                    </Link>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item >
                    <Link onClick={scrollToTop} to="/aboutZemo">
                      About Zemo
                    </Link>
                  </NavDropdown.Item> */}
                  <NavDropdown.Item>
                    <Link onClick={scrollToTop} to="/contactUsPage">
                      Contact Us
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              <li className="nav-item">
                {(isUserLoggedIn && userDetails?.roleId === 3 || isUserLoggedIn && userDetails?.roleId === 4) ?
                  <NavLink className="nav-link " to="/feed">
                    Zemo Suite
                  </NavLink>
                  :
                  <NavLink className="nav-link " to="/managementSystem">
                    Zemo Suite
                  </NavLink>}
              </li>

              {/* <li className="nav-item">
                <NavLink className="nav-link" onClick={handleMenuItemClick} aria-current="page" to="/zemoForOrganizer">
                  Organizer
                </NavLink>
              </li> */}
              {/* Link appears if user is not logged In or if user is logged in as player */}
              {(!isUserLoggedIn || (isUserLoggedIn && userDetails?.roleId === 2)) && (
                <>
                  <div
                    className="nav-dropdown-container"
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    <NavDropdown
                      title="Tournaments"
                      id="basic-nav-dropdown"
                      show={dropdownOpen}
                    >
                      <NavDropdown.Item >
                        <Link onClick={handleMenuItemClick} to="/browse-tournaments?upComing=1">
                          Upcoming
                        </Link>

                      </NavDropdown.Item>
                      <NavDropdown.Item href="">
                        <Link onClick={handleMenuItemClick} to="/browse-tournaments?onGoing=1">
                          Ongoing
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/">
                        <Link onClick={handleMenuItemClick} to="/browse-tournaments?recentlyConclud=1">
                          Concluded
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </>
              )}

              {/* link appears if user is logged in as organizer */}
              {isUserLoggedIn && userDetails?.roleId === 3 && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" onClick={handleMenuItemClick} to="/createTournament">
                      Create Tournament
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" onClick={handleMenuItemClick} to="/tournament">
                      Manage Tournaments
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink onClick={handleMenuItemClick} className="nav-link" to="/ticket-list">
                      Bookings
                    </NavLink>
                  </li> */}
                </>
              )}

              {/* link appears if user is logged in as player */}
              {isUserLoggedIn && userDetails?.roleId === 2 && (
                <>
                  <li className="nav-item">
                    <NavLink onClick={handleMenuItemClick} className="nav-link" to="/ticket-list">
                      Bookings
                    </NavLink>
                  </li>
                </>
              )}
            </ul>

            {!isUserLoggedIn ? (
              <div className="loginButton">
                <NavLink
                  onClick={()=>handleMenuItemClick("login")}
                  to="/login" className="btn btn-light login-btn mx-2">
                  Login
                </NavLink>
                <NavLink
                  to="/register"
                  onClick={()=>scrollToTop("register")}
                  className="btn btn-outline-light register-btn"
                >
                  Create an account
                </NavLink>
              </div>

            ) : (
              <div className="userInfo">
                {/* {(userDetails?.roleId === 1 || userDetails?.roleId === 3) &&
                  <>
                    <NavLink
                      onClick={() => navigate('suite')}>
                      <span className="suite-btn">Zemo Suite</span>
                    </NavLink>
                  </>
                } */}
                <NavLink
                  onClick={handleMenuItemClick}
                  to="/notifications"
                  className="btn btn-outline-dark notification-btn mx-2 position-relative"
                >
                  <img
                    src={img_url + "notificationIcon.svg"}
                    alt=""
                    className="notification-icon"
                  />
                </NavLink>
                <NavLink>
                  <Logout closeNav={scrollToTop} />
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </nav >
    </div >
  );
};

export default Navbar;
