import React from "react";
import LandingPage from "./LandingPage";

const LiveScorePage = () => {

    const contentText = {
        paragraph1: "Your ultimate engagement tool!",
        paragraph2: "With our Live Scoring feature, you can keep your community glued to the action. Whether it's a thrilling match in progress or a nail-biting finish, you can track it all in real-time.",
        paragraph3: "Personalise the experience by adding player or team names, photos, or logos for that extra professional touch. Plus, once the match wraps up, simply save the results. Our system automatically generates a sleek and shareable creative, ready to dazzle your social media followers.",
        paragraph4: "And to ensure accuracy and security, scorers get their own user login, protecting your tournament data.",
        paragraph5: "Level up your tournament's appeal and excitement with our Live Scoring feature. Give it a try today!",
    };

    const features = [
        {
            title: "Match Arena",
            description: "A dedicated, customizable page exclusively for your tournament, giving it a professional touchand a unique digital presence.",
            image: "match-arena.svg",
        },
        {
            title: "Real Time Updates",
            description: "Keep fans and participants in the loop by sharing live progress for every match as it unfolds.",
            image: "realtime-update.svg",
        },
        {
            title: "Multiple feeds",
            description: "Deliver real time scores online, on live streams andwithin the arena effortlessly - all at the same time. ",
            image: "multiple-feed.svg",
        },
        {
            title: "Create Poll",
            description: "Boost interaction by conducting real-time pollsduring the match, letting the audience share their thoughts and predictions.",
            image: "poll.svg",
        },
        {
            title: "Player Stats",
            description: "Dive deep with player performance data, providing insightful Statistics to analyze every move.",
            image: "statistic.svg",
        },
        {
            title: "Match Scorecard",
            description: "Capture match results and automatically generate a polished, shareable scorecard that’s ready for social media.",
            image: "Score-Card.svg",
        },
        {
            title: "Sponsor Visibility",
            description: "Maximize brand exposure by showcasing partner logos prominently, ensuring they get the attention they deserve.",
            image: "sponsor-visibility.svg",
        },
        {
            title: "Scorer Login",
            description: "Ensure match accuracy with unique scorer logins, allowing assigned scorers to update scores securely and independently.",
            image: "scorer-login.svg",
        }
    ];

    return (
        <>
            <LandingPage
                pageTitle={"LIVE SCORING FEATURES"}
                features={features}
                contentText={contentText}
            />
        </>
    )
}

export default LiveScorePage;