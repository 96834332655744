import React from "react";
import LandingPage from "./LandingPage";

const AuctionBayPage = () => {

    const contentText = {
        paragraph1: "Take your auction events to the next level!",
        paragraph2: "with ZEMO’s Auction Bay, a feature designed to streamline every aspect of auction management. Whether you’re hosting a live auction or managing bids online, our platform ensures a seamless and engaging experience for organizers and participants.",
    };

    const features = [
        {
            title: "Custom Auction Setup",
            description: "Easily configure auction rules, item listings, and starting prices tailored to your event.",
            image: "custom-auction.svg",
        },
        {
            title: "Real-Time Bidding",
            description: "Enable dynamic, live bidding with instant updates and seamless participation.",
            image: "realtime-bidding.svg",
        },
        {
            title: "Customizable Dashboard",
            description: "Manage all auction activities from one powerful, user-friendly interface.",
            image: "customicable-dashboard.svg",
        },
        {
            title: "Bid Tracking",
            description: "Keep a clear record of all bids and participants for transparent operations.",
            image: "bid-tracking.svg",
        },
        {
            title: "Participant Registration",
            description: "Allow bidders to easily register and verify their details online, ensuring a secure and organized auction process.",
            image: "participant-registration.svg",
        },
        {
            title: "Sponsor Integration",
            description: "Highlight sponsor logos and advertisements during the auction, creating a professional look while boosting sponsor visibility.",
            image: "sponsor-intigration.svg",
        },
        {
            title: "Pre-Auction Analytics",
            description: "Access insights on player performance, popularity, or past bidding trends to help organizers and participants make informed decisions.",
            image: "preauction-analytics.svg",
        },
        {
            title: "Post-Auction Summary",
            description: "Generate detailed reports with bidding history, winner lists, and financial summaries for quick review and record-keeping.",
            image: "postauction-summary.svg",
        },
    ];

    return (
        <>
            <LandingPage
                pageTitle={"AUCTION BAY FEATURES"}
                features={features}
                contentText={contentText}
            />
        </>
    )
}

export default AuctionBayPage;