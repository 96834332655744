import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ContactOrganizer from './contact';
import Api from '../../../api/Api';
import moment from "moment";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { toast } from "react-toastify";

const DetailsForm = () => {
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const pendingTicketDetails = localStorage.getItem('pendingTicket');
    const pendingTicket = pendingTicketDetails ? JSON.parse(pendingTicketDetails) : null;
    const parseTournamentDetails = localStorage.getItem("tournamentDetails");
    const tournamentId = localStorage.getItem("selectedTournamentId");
    const tournamentDetails = parseTournamentDetails ? JSON.parse(parseTournamentDetails) : null;
    let couponValue = localStorage.getItem("couponValue");
    let ticketDetails = useSelector((state) => state.ticketDetails);
    if (!ticketDetails || Object.keys(ticketDetails)?.length === 0) {
        const parseticketData = localStorage.getItem("ticketData");
        ticketDetails = parseticketData ? JSON.parse(parseticketData) : null;
    }
    const organiserId = tournamentDetails?.user_id;
    const sportsId = ticketDetails?.ticketData[0]?.category_id;
    const ticketsArray = ticketDetails ? JSON.parse(ticketDetails?.ticketArray) : null;
    const imageUrl = '../../.././images/';
    const [formData, setFormData] = useState();
    const [isFormValid, setIsFormValid] = useState(Array(formData?.formData?.length).fill(false));
    const [showPlayerDetails, setShowPlayerDetails] = useState(false);
    const [playerResponse, setPlayerResponse] = useState([]);
    const [pendingForm, setPendingForm] = useState();
    const [saveFormData, setSaveFormData] = useState();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState([]);
    const [isFormModified, setIsFormModified] = useState(false);
    const [ticketAvailable, setTicketAvailable] = useState(JSON.parse(localStorage.getItem('ticketAvailability')) || false);
    const [ticketsArrays, setTicketsArrays] = useState([]);
    const [submitForm, setSubmitForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [defaultValueSaved, setDefaultValueSaved] = useState(false);
    const [emptyAnswer, setEmptyAnswer] = useState(false);
    const [checkedBox, setCheckedBox] = useState(false);
    const [allTicketFormMap, setAllTicketFormMap] = useState({});
    const [uploadData, setUploadData] = useState({
        accessKeyId: null,
        secretAccessKey: null,
        file: null,
        label: null,
        organiserId: null,
        sportsId: null,
        tournamentId: null,
        formFieldIndex: null,
    });
    const [uploadData2, setUploadData2] = useState({
        accessKeyId2: null,
        secretAccessKey2: null,
        file2: null,
        label: null,
        organiserId: null,
        sportsId: null,
        tournamentId: null,
    });

    let s3 = new AWS.S3();

    // add condition for terms
    const checkedForm = (isChecked, dataParams) => {
        let updatedAllTicketFormMap = allTicketFormMap;
        if (dataParams?.teamPlayerForm) {
            const newAllTicketFormMap = {
                ...allTicketFormMap,  // Copy all tickets
                [dataParams.ticketId]: {  // Access specific ticket by ticketId
                    ...allTicketFormMap[dataParams.ticketId],  // Copy the specific ticket's data
                    forms: allTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                        formIndex === dataParams.formIndex  // Check if the current formIndex matches
                            ? {
                                ...form,  // Copy the form data
                                playerFormResponse: form.playerFormResponse.map((playerForm, playerFormIndex) =>
                                    playerFormIndex === dataParams.playerFormIndex  // Check if the current playerFormIndex matches
                                        ? {
                                            ...playerForm,  // Copy the player form data
                                            termsAndConditions: isChecked
                                        }
                                        : playerForm  // Keep the player form unchanged if playerFormIndex doesn't match
                                )
                            }
                            : form  // Keep the form unchanged if formIndex doesn't match
                    )
                }
            };
            updatedAllTicketFormMap = newAllTicketFormMap;
            setAllTicketFormMap(newAllTicketFormMap);
            const formCheck = Object.values(updatedAllTicketFormMap).every(category =>
                category.forms.every(form => {
                    const formConditions = form.saveForm === true && form.termsAndConditions === true;
                    const playerFormResponseConditions = form.playerFormResponse
                        ? form.playerFormResponse.every(playerForm => playerForm.saveForm === true && playerForm.termsAndConditions === true)
                        : true;
                    return formConditions && playerFormResponseConditions;
                })
            );
            // Update the submitForm state with the new array
            setSubmitForm(formCheck);
        }
        else {
            const newAllTicketFormMap = {
                ...allTicketFormMap,
                [dataParams.ticketId]: {
                    ...allTicketFormMap[dataParams.ticketId],
                    forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                        formIndex === dataParams.formIndex
                            ? {
                                ...form,
                                termsAndConditions: isChecked
                            }
                            : form
                    )
                }
            };
            updatedAllTicketFormMap = newAllTicketFormMap;
            setAllTicketFormMap(newAllTicketFormMap);
            const formCheck = Object.values(updatedAllTicketFormMap).every(category =>
                category.forms.every(form => {
                    const formConditions = form.saveForm === true && form.termsAndConditions === true;
                    const playerFormResponseConditions = form.playerFormResponse
                        ? form.playerFormResponse.every(playerForm => playerForm.saveForm === true && playerForm.termsAndConditions === true)
                        : true;
                    return formConditions && playerFormResponseConditions;
                })
            );
            // Update the submitForm state with the new array
            setSubmitForm(formCheck);
        }
    }

    // form compress
    const togglePlayerDetails = () => {
        setShowPlayerDetails(prevState => !prevState);
    }

    useEffect(() => {
        dynamicForm();
        getFormDetails();
        if (pendingTicket?.status === "pending") {
            checkTicketAvailable();
        }
        if (ticketDetails?.quantities) {
            let arr = [];
            for (let i = 0; i < ticketDetails?.quantities; i++) {
                arr.push(false)
            }
            setIsCheckboxChecked(arr);
            // setSubmitForm(arr);
        }
    }, [emptyAnswer]);

    // check ticket if coming from pending ticket
    const checkTicketAvailable = () => {
        const ticketIndex = ticketsArrays.findIndex(ticket => ticket.id === pendingTicket.ticketId);
        if (ticketIndex !== -1) {
            ticketsArrays[ticketIndex].quantity = pendingTicket?.booking_pending_player_response?.length;
        } else {
            ticketsArrays.push({
                id: pendingTicket.ticketId,
                quantity: pendingTicket?.booking_pending_player_response?.length,
            });
        }
        const params = {
            tickets: ticketsArrays,
            paymentMethod: pendingTicket?.ticketPrice > 0 ? "online" : "offline",
            eventId: pendingTicket?.event_id,
            bookingDate: moment(pendingTicket?.start_date).format('YYYY-MM-DD'),
            startTime: pendingTicket?.start_time,
            endTime: pendingTicket?.end_time,
        }
        if (couponValue) {
            params.couponCode = couponValue;
        }
        Api.call(`checkBookingTicketAvailable`, 'POST', params)
            .then(async (response) => {
                setTicketAvailable(response);
                if (response.status === false) {
                    toast.error("Ticket not available for booking.");
                }
            })
    }

    // Function to initialize Razorpay
    const initializeRazorpay = (id, amount) => {
        const options = {
            key: 'rzp_live_CJjrX8WFHyyC2Z',
            amount: amount * 100,
            currency: 'INR',
            name: '',
            description: 'Tournament Entry Fee',
            order_id: id,
            handler: function (response) {
                // Handle successful payment response
                console.log('Payment successful:', response);
                // After successful payment, proceed to book the ticket
                if (response) {
                    bookTicketOnline(response);
                };
            },
            prefill: {
                name: '',
                email: '',
                contact: '',
            },
            notes: {
                // Add any additional notes if needed
            },
            theme: {
                color: '#F37254',
            },
        };

        if (window.Razorpay) {
            const rzp = new window.Razorpay(options);
            rzp.open();
        } else {
            // Razorpay script not loaded yet, handle accordingly
            console.error('Razorpay script not loaded.');
        }
    };


    // api call for dynamic form
    const dynamicForm = () => {
        if (tournamentId) {
            Api.call(`form?tournamentId=${tournamentId}`, 'GET')
                .then(async (response) => {
                    let finalForm = [];
                    response.forEach(form => {
                        // delete formConfigurationData
                        delete form.formConfigurationData;
                        // Check if a form with the same category_id and tournament_id exists
                        let existingForm = finalForm.find(f => f.category_id === form.category_id && f.tournament_id === form.tournament_id);
                        if (existingForm) {
                            // If the existing form has identifier 'team' and the current form has no identifier, nest it
                            if (existingForm.identifier === "team" && (form.identifier === null || form.identifier === "player")) {
                                existingForm.playerForm = form
                                existingForm.playerFormResponse = [form]
                            }
                        } else {
                            // If no existing form, simply add the current form to finalForm
                            finalForm.push(form);
                        }
                    });


                    let allTicketFormMap_data = {};
                    for (let i = 0; i < ticketsArray.length; i++) {
                        let ticket = ticketsArray[i];
                        // Check if form exists for this ticket's categoryId
                        let form = finalForm && finalForm?.find(form => form.category_id === ticket.categoryId);
                        // If form exists, push a new object representing the form into allForms for each ticket quantity
                        if (form) {
                            for (let j = 0; j < ticket.quantity; j++) {
                                if (allTicketFormMap_data[ticket.id]) {
                                    allTicketFormMap_data[ticket.id].forms.push(form);
                                } else {
                                    allTicketFormMap_data[ticket.id] = {
                                        id: ticket.id,
                                        name: ticket.ticketName,
                                        categoryId: ticket.categoryId,
                                        categoryName: ticket.categoryName,
                                        forms: [form]
                                    };
                                }
                            }
                        }
                    }
                    setFormData(finalForm);
                    setAllTicketFormMap(allTicketFormMap_data);
                });
        }
    }

    // date and time format
    const startDate = moment(pendingTicket?.status === "pending" ? pendingTicket?.start_date : tournamentDetails?.start_date);
    const startTime = moment(pendingTicket?.status === "pending" ? pendingTicket?.start_time : tournamentDetails?.start_time, "HH:mm");

    // Parse the end date and time strings using moment
    const endDate = moment(pendingTicket?.status === "pending" ? pendingTicket?.end_date : tournamentDetails?.end_date);
    const endTime = moment(pendingTicket?.status === "pending" ? pendingTicket?.end_time : tournamentDetails?.end_time, "HH:mm");

    // Format the start and end date and time strings
    const formattedStartDate = startDate.format("D MMM YYYY");
    const formattedStartTime = startTime.format("HH:mm A");

    const formattedEndDate = endDate.format("D MMM YYYY");
    const formattedEndTime = endTime.format("HH:mm A");

    // set input values
    const handleInputChange = (e, dataParams) => {
        let { value } = e.target;
        const newValue = value;
        // if condition for team player form 
        if (dataParams?.teamPlayerForm) {
            const newAllTicketFormMap = {
                ...allTicketFormMap,  // Copy all tickets
                [dataParams.ticketId]: {  // Access specific ticket by ticketId
                    ...allTicketFormMap[dataParams.ticketId],  // Copy the specific ticket's data
                    forms: allTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                        formIndex === dataParams.formIndex  // Check if the current formIndex matches
                            ? {
                                ...form,  // Copy the form data
                                playerFormResponse: form.playerFormResponse.map((playerForm, playerFormIndex) =>
                                    playerFormIndex === dataParams.playerFormIndex  // Check if the current playerFormIndex matches
                                        ? {
                                            ...playerForm,  // Copy the player form data
                                            formData: playerForm.formData.map((item, i) =>
                                                i === dataParams.formFieldIndex  // Check if the current field index matches
                                                    ? { ...item, answer: newValue }  // Update the answer if indices match
                                                    : item  // Keep the item unchanged if indices don't match
                                            )
                                        }
                                        : playerForm  // Keep the player form unchanged if playerFormIndex doesn't match
                                )
                            }
                            : form  // Keep the form unchanged if formIndex doesn't match
                    )
                }
            };
            setAllTicketFormMap(newAllTicketFormMap);

        } else {
            // Deep copy of allTicketFormMap to ensure immutability
            const newAllTicketFormMap = {
                ...allTicketFormMap,
                [dataParams.ticketId]: {
                    ...allTicketFormMap[dataParams.ticketId],
                    forms: allTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                        formIndex === dataParams.formIndex
                            ? {
                                ...form,
                                formData: form.formData.map((item, i) =>
                                    i === dataParams.formFieldIndex
                                        ? { ...item, answer: newValue }
                                        : item
                                )
                            }
                            : form
                    )
                }
            };
            setAllTicketFormMap(newAllTicketFormMap);
        }
    };


    // handle change for panding
    const handlePendingForm = (e, formIndex, itemLabel) => {
        const { value } = e.target;
        let isFormModified = false;
        const updatedPendingForm = pendingForm.map((form, idx) => {
            if (idx === formIndex) {
                // Find the form item with the specified label and update its answer
                return form.map((item) => {
                    if (item.label === itemLabel) {
                        if (item.answer !== value) {
                            isFormModified = true;
                        }
                        return {
                            ...item,
                            answer: value,
                        };
                    }
                    return item;
                });
            }
            return form;
        });
        const flattenedForm = updatedPendingForm.flat();
        setPendingForm(updatedPendingForm);
        setFormData({ ...pendingForm, formData: flattenedForm });
        setIsFormModified(isFormModified);
    };

    // check ticket Available
    const bookTicketInitiate = async () => {
        if (ticketDetails?.totalEntry > 0) {
            const params = {
                tickets: ticketsArray,
                paymentMethod: "online",
                eventId: tournamentDetails?.id,
                bookingDate: moment(tournamentDetails?.start_date).format('YYYY-MM-DD'),
                startTime: tournamentDetails?.start_time,
                endTime: tournamentDetails?.end_time,
                createPendingOrder: ticketDetails?.totalEntry > 0 ? true : false,
            }
            if (couponValue) {
                params.couponCode = couponValue;
            }
            Api.call(`bookTicketInitiate`, 'POST', params)
                .then(async (response) => {
                    if (response.id) {
                        initializeRazorpay(response.id, ticketDetails?.totalEntry)
                    }
                })
        } else {
            bookTicket('offline');
        }
    }

    // call aws api 
    const handleFileChange = async (event, label, index) => {
        const file = event.target.files[0];
        Api.CoreApiToken(`aws/config`, "GET")
            .then((response) => {
                const accessKeyId = response?.data[0].access_key;
                const secretAccessKey = response?.data[0].secret_key;
                if (response) {
                    if (label == "Upload necessary document" || label == "Upload Aadhar Card (Image size upto 1 MB)") {
                        setUploadData2({
                            accessKeyId: accessKeyId,
                            secretAccessKey: secretAccessKey,
                            file: file,
                            label: label,
                            organiserId: organiserId,
                            sportsId: sportsId,
                            tournamentId: tournamentDetails?.id,
                            formFieldIndex: index,
                        });
                    } else {
                        setUploadData({
                            accessKeyId: accessKeyId,
                            secretAccessKey: secretAccessKey,
                            file: file,
                            label: label,
                            organiserId: organiserId,
                            sportsId: sportsId,
                            tournamentId: tournamentDetails?.id,
                            formFieldIndex: index,
                        });
                    }
                    // handleUpload(accessKeyId, secretAccessKey, file, label, organiserId, sportsId, tournamentDetails?.id, userDetails?.id)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //upload the file to the AWS S3 bucket
    const handleUpload = async (accessKeyId, secretAccessKey, file, label, organiserId, sportsId, tournamentId, userId) => {
        const fileName = file.name.split('.').slice(0, -1).join('.');
        const fileExtension = file.name.split('.').pop();
        const region = "us-east-1";
        const bucketName = "zemo-creatives";
        const key = `${organiserId}/${sportsId}/${tournamentId}/Players/${fileName}_${userId}.${fileExtension}`;
        AWS.config.update({
            accessKeyId,
            secretAccessKey,
            region,
        });
        const s3 = new AWS.S3();
        const params = {
            Bucket: bucketName,
            Key: key,
            Body: file,
            ContentType: "image/png",
        };
        try {
            const data = await s3.upload(params).promise();
            const updatedFormData = formData?.formData?.map(item => {
                if (item.type === '7' && item.label === label) {
                    return {
                        ...item,
                        answer: data.Location,
                    };
                }
                return item;
            });
            setLoader(false);
            setFormData({ ...formData, formData: updatedFormData });
            return data.Location;
        } catch (err) {
            console.error("Error uploading the image", err);
            throw err;
        }
    };

    // api call bookticket offline
    const bookTicket = (paymentMethod, data) => {
        let params = {
            tickets: ticketsArray,
            paymentMethod: paymentMethod,
            eventId: tournamentDetails?.id,
            bookingDate: moment(tournamentDetails?.start_date).format('YYYY-MM-DD'),
            startTime: tournamentDetails?.start_time,
            endTime: tournamentDetails?.end_time,
        };
        Api.call(`bookTicket`, "POST", params)
            .then((response) => {
                if (response.message) {
                    toast.success(response.message);
                    navigate('/ticket-list');
                    localStorage.removeItem("ticketAvailability");
                    localStorage.removeItem("couponValue");
                } else if (response.error) {
                    toast.error("Ticket can not book.")
                }
            })
    }

    // api call bookticket online
    const bookTicketOnline = (data) => {
        let params = {
            paymentOrderId: data.razorpay_order_id,
            paymentId: data.razorpay_payment_id,
        };
        Api.call(`bookTicketPayment`, "POST", params)
            .then((response) => {
                if (response.message) {
                    toast.success(response.message);
                    navigate('/ticket-list');
                    localStorage.removeItem("ticketAvailability");
                    localStorage.removeItem("couponValue");
                }
            })
    }

    // api call for save form
    const saveForm = async (dataParams) => {
        let formData = allTicketFormMap[dataParams?.ticketId]?.forms[dataParams?.formIndex];
        let userName = formData?.formData[0]?.answer?.length !== 0 ? formData?.formData[0]?.answer : userDetails?.name;
        if (dataParams?.teamPlayerForm) {
            userName = formData?.playerFormResponse[dataParams?.playerFormIndex]?.formData[0]?.answer?.length !== 0 ? formData?.playerFormResponse[dataParams?.playerFormIndex]?.formData[0]?.answer : userDetails?.name;
        }
        let saveFileData;
        let saveFileData2;
        if (uploadData?.file) {
            setLoader(true);
            const accessKeyId = uploadData?.accessKeyId;
            const secretAccessKey = uploadData?.secretAccessKey;
            const file = uploadData?.file;
            const tournamentId = tournamentDetails?.id;
            const fileExtension = file?.name?.split('.').pop();
            const region = "us-east-1";
            const bucketName = "zemo-creatives";
            const key = `${organiserId}/${sportsId}/${tournamentId}/Players/${userName}_${userDetails?.id}.${fileExtension}`;
            AWS.config.update({
                accessKeyId,
                secretAccessKey,
                region,
            });
            const s3 = new AWS.S3();
            const params = {
                Bucket: bucketName,
                Key: key,
                Body: file,
                ContentType: "image/png",
            };

            saveFileData = await s3.upload(params).promise(); // for image
            if (saveFileData.Location) {
                setLoader(false);
            }
        }

        if (uploadData2?.file) {
            setLoader(true);
            const accessKeyId = uploadData2?.accessKeyId;
            const secretAccessKey = uploadData2?.secretAccessKey;
            const file = uploadData2?.file;
            const tournamentId = tournamentDetails?.id;
            const fileExtension = file?.name?.split('.').pop();
            const region = "us-east-1";
            const bucketName = "zemo-creatives";
            const key = `${organiserId}/${sportsId}/${tournamentId}/Players/${userName}_${userDetails?.id}`;
            AWS.config.update({
                accessKeyId,
                secretAccessKey,
                region,
            });
            const s3 = new AWS.S3();
            const params = {
                Bucket: bucketName,
                Key: key + file.name,
                Body: file,
                // ContentType: "image/png",
            };

            saveFileData2 = await s3.upload(params).promise(); // for image
            if (saveFileData2.Location) {
                setLoader(false);
            }
        }

        let updatedAllTicketFormMap = allTicketFormMap;
        if (saveFileData?.Location) {
            let newValue = saveFileData?.Location;
            if (dataParams?.teamPlayerForm) {
                const newAllTicketFormMap = {
                    ...updatedAllTicketFormMap,  // Copy all tickets
                    [dataParams.ticketId]: {  // Access specific ticket by ticketId
                        ...updatedAllTicketFormMap[dataParams.ticketId],  // Copy the specific ticket's data
                        forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                            formIndex === dataParams.formIndex  // Check if the current formIndex matches
                                ? {
                                    ...form,  // Copy the form data
                                    playerFormResponse: form.playerFormResponse.map((playerForm, playerFormIndex) =>
                                        playerFormIndex === dataParams.playerFormIndex  // Check if the current playerFormIndex matches
                                            ? {
                                                ...playerForm,  // Copy the player form data
                                                formData: playerForm.formData.map((item, i) =>
                                                    i === uploadData?.formFieldIndex // Check if the current field index matches
                                                        ? { ...item, answer: newValue }  // Update the answer if indices match
                                                        : item  // Keep the item unchanged if indices don't match
                                                )
                                            }
                                            : playerForm  // Keep the player form unchanged if playerFormIndex doesn't match
                                    )
                                }
                                : form  // Keep the form unchanged if formIndex doesn't match
                        )
                    }
                };
                updatedAllTicketFormMap = newAllTicketFormMap;
                setAllTicketFormMap(newAllTicketFormMap);
            }
            else {
                const newAllTicketFormMap = {
                    ...updatedAllTicketFormMap,
                    [dataParams.ticketId]: {
                        ...updatedAllTicketFormMap[dataParams.ticketId],
                        forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                            formIndex === dataParams.formIndex
                                ? {
                                    ...form,
                                    formData: form.formData.map((item, i) =>
                                        i === uploadData?.formFieldIndex
                                            ? { ...item, answer: newValue }
                                            : item
                                    )
                                }
                                : form
                        )
                    }
                };
                updatedAllTicketFormMap = newAllTicketFormMap;
                setAllTicketFormMap(newAllTicketFormMap);
            }
        }

        if (saveFileData2?.Location) {
            let newValue = saveFileData2?.Location;
            if (dataParams?.teamPlayerForm) {
                const newAllTicketFormMap = {
                    ...updatedAllTicketFormMap,  // Copy all tickets
                    [dataParams.ticketId]: {  // Access specific ticket by ticketId
                        ...updatedAllTicketFormMap[dataParams.ticketId],  // Copy the specific ticket's data
                        forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                            formIndex === dataParams.formIndex  // Check if the current formIndex matches
                                ? {
                                    ...form,  // Copy the form data
                                    playerFormResponse: form.playerFormResponse.map((playerForm, playerFormIndex) =>
                                        playerFormIndex === dataParams.playerFormIndex  // Check if the current playerFormIndex matches
                                            ? {
                                                ...playerForm,  // Copy the player form data
                                                formData: playerForm.formData.map((item, i) =>
                                                    i === uploadData2?.formFieldIndex // Check if the current field index matches
                                                        ? { ...item, answer: newValue }  // Update the answer if indices match
                                                        : item  // Keep the item unchanged if indices don't match
                                                )
                                            }
                                            : playerForm  // Keep the player form unchanged if playerFormIndex doesn't match
                                    )
                                }
                                : form  // Keep the form unchanged if formIndex doesn't match
                        )
                    }
                };
                updatedAllTicketFormMap = newAllTicketFormMap;
                setAllTicketFormMap(newAllTicketFormMap);
            }
            else {
                const newAllTicketFormMap = {
                    ...updatedAllTicketFormMap,
                    [dataParams.ticketId]: {
                        ...updatedAllTicketFormMap[dataParams.ticketId],
                        forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                            formIndex === dataParams.formIndex
                                ? {
                                    ...form,
                                    formData: form.formData.map((item, i) =>
                                        i === uploadData2?.formFieldIndex
                                            ? { ...item, answer: newValue }
                                            : item
                                    )
                                }
                                : form
                        )
                    }
                };
                updatedAllTicketFormMap = newAllTicketFormMap;
                setAllTicketFormMap(newAllTicketFormMap);
            }
        }

        const outerFilledForm = updatedAllTicketFormMap[dataParams.ticketId].forms[dataParams.formIndex]
        const hasEmptyAnswer = outerFilledForm?.formData?.some(item => item.isRequired && (!item.answer || item.answer.length === 0));

        if (hasEmptyAnswer) {
            toast.error("Please fill mandatory field.");
            return;
        } else if (!outerFilledForm?.termsAndConditions) {
            toast.error("Please checked terms and rules.");
            return;
        } else {
            if (dataParams?.teamPlayerForm) {
                // Update the value at the specified index
                let innerFilledForm = updatedAllTicketFormMap[dataParams.ticketId].forms[dataParams.formIndex].playerFormResponse[dataParams.playerFormIndex]

                const hasEmptyAnswer = innerFilledForm && innerFilledForm?.formData?.some(item => item.isRequired && (!item.answer || item.answer.length === 0));
                if (hasEmptyAnswer) {
                    toast.error("Please fill mandatory field.");
                    return;
                } else if (!innerFilledForm?.termsAndConditions) {
                    toast.error("Please checked terms and rules.");
                    return;
                }
                const newAllTicketFormMap = {
                    ...updatedAllTicketFormMap,  // Copy all tickets
                    [dataParams.ticketId]: {  // Access specific ticket by ticketId
                        ...updatedAllTicketFormMap[dataParams.ticketId],  // Copy the specific ticket's data
                        forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                            formIndex === dataParams.formIndex  // Check if the current formIndex matches
                                ? {
                                    ...form,  // Copy the form data
                                    playerFormResponse: form.playerFormResponse.map((playerForm, playerFormIndex) =>
                                        playerFormIndex === dataParams.playerFormIndex  // Check if the current playerFormIndex matches
                                            ? {
                                                ...playerForm,  // Copy the player form data
                                                saveForm: true
                                            }
                                            : playerForm  // Keep the player form unchanged if playerFormIndex doesn't match
                                    )
                                }
                                : form  // Keep the form unchanged if formIndex doesn't match
                        )
                    }
                };
                updatedAllTicketFormMap = newAllTicketFormMap;
                setAllTicketFormMap(newAllTicketFormMap);
            }
            else {
                const newAllTicketFormMap = {
                    ...updatedAllTicketFormMap,
                    [dataParams.ticketId]: {
                        ...updatedAllTicketFormMap[dataParams.ticketId],
                        forms: updatedAllTicketFormMap[dataParams.ticketId].forms.map((form, formIndex) =>
                            formIndex === dataParams.formIndex
                                ? {
                                    ...form,
                                    saveForm: true
                                }
                                : form
                        )
                    }
                };
                updatedAllTicketFormMap = newAllTicketFormMap;
                setAllTicketFormMap(newAllTicketFormMap);
            }

            let playerResponse = [];
            updatedAllTicketFormMap[dataParams?.ticketId]?.forms?.forEach(element => {
                if (element.saveForm) {
                    if (dataParams?.teamPlayerForm) {
                        let teamPlayers = [];
                        element.playerFormResponse.forEach(playerElement => {
                            if (playerElement.saveForm) {
                                teamPlayers.push(playerElement.formData);
                            }
                        });
                        // Find the "Team Players" entry in formData
                        let teamPlayersEntry = element.formData.find(data => data.value === "teamPlayers");
                        if (teamPlayersEntry) {
                            // Update the existing "Team Players" entry
                            teamPlayersEntry.answer = teamPlayers;
                        } else {
                            // Add a new "Team Players" entry
                            element.formData.push({
                                label: "Team Players",
                                type: -1,
                                value: "teamPlayers",
                                answer: teamPlayers
                            });
                        }
                    }
                    playerResponse.push(element.formData);
                }
            });

            let data = {
                ticketId: dataParams?.ticketId,
                ticketName: updatedAllTicketFormMap[dataParams?.ticketId]?.name,
                eventId: tournamentDetails?.id,
                eventName: tournamentDetails?.title,
                playerResponse: playerResponse
            }
            if (playerResponse.length > 0) {
                Api.call(`bookingForm`, "POST", {
                    formData: [data]
                })
                    .then((response) => {
                        setCheckedBox(true);
                        if (response.status === true) {
                            const formCheck = Object.values(updatedAllTicketFormMap).every(category =>
                                category.forms.every(form => {
                                    const formConditions = form.saveForm === true && form.termsAndConditions === true;
                                    const playerFormResponseConditions = form.playerFormResponse
                                        ? form.playerFormResponse.every(playerForm => playerForm.saveForm === true && playerForm.termsAndConditions === true)
                                        : true;
                                    return formConditions && playerFormResponseConditions;
                                })
                            );
                            // Update the submitForm state with the new array
                            setSubmitForm(formCheck);
                            if (response.message) {
                                toast.success(response.message);
                            }
                        }
                    })
            }


        }
    }

    // api call for form details
    const getFormDetails = () => {
        const ids = ticketsArray?.map(obj => obj.id);
        const idsString = ids?.toString();
        try {
            if (pendingTicket?.status === "pending") {
                const ticketId = pendingTicket?.ticketId;
                const eventId = pendingTicket?.event_id;
                if (!ticketId || !eventId) {
                    console.error('Ticket ID or Event ID is missing.');
                    return;
                }
                const Query = `?ticketIds=${ticketId}&eventId=${eventId}`;
                Api.call(`bookingForm${Query}`, "GET")
                    .then((response) => {
                        setPendingForm(response[0]?.booking_pending_player_response);
                    })
            } else {
                const ticketId = ticketDetails?.ticketId;
                const eventId = tournamentDetails?.id;
                const ids = ticketsArray?.map(obj => obj.id); // Extracting IDs from ticketsArray
                const jsonIds = encodeURIComponent(JSON.stringify(ids));  // Encoding the ticket IDs array
                if (!ticketId || !eventId) {
                    console.error('Ticket ID or Event ID is missing.');
                    return;
                }
                const Query = `?ticketIds=${jsonIds}&eventId=${eventId}`;
                Api.call(`bookingForm${Query}`, "GET")
                    .then((response) => {
                        setPendingForm(response[0]?.booking_pending_player_response);
                    })
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // dynamic name map from login details
    const getUserFieldValue = (fieldName, fullName, userData) => {
        // Split the full name 
        const nameParts = fullName.split(' ');

        // Return the corresponding part based on the field name
        switch (fieldName) {
            case 'nameofparticipant':
                return fullName; // If coming full name
            case 'firstname':
                return nameParts[0] || ''; // If first name is not available, return empty string
            case 'middlename':
                return nameParts.slice(1, -1).join(' ') || ''; // Join middle name parts with space
            case 'lastname':
                return nameParts.length > 1 ? nameParts.slice(-1)[0] : ''; // Get the last part as last name
            case 'emailaddress':
                return userData.email || '';
            default:
                return '';
        }
    };

    // add more plyer 

    const addMorePlayer = (dataParams) => {
        // Example new playerFormResponse to be added
        const newPlayerFormResponse = allTicketFormMap[dataParams?.ticketId]?.forms[0].playerForm;
        // Ensure that playerFormResponse array exists, otherwise initialize it
        const updatedForms = allTicketFormMap[dataParams.ticketId].forms.map((form, index) => {
            if (index === dataParams.formIndex) {
                return {
                    ...form,
                    playerFormResponse: [
                        ...(form.playerFormResponse || []),
                        newPlayerFormResponse
                    ]
                };
            }
            return form;
        });

        const newAllTicketFormMap = {
            ...allTicketFormMap,
            [dataParams.ticketId]: {
                ...allTicketFormMap[dataParams.ticketId],
                forms: updatedForms
            }
        };
        setAllTicketFormMap(newAllTicketFormMap);
    };

    const deleteHandle = (id) => {
        if (tournamentId) {
            Api.call(`form?id=${id}`, 'DELETE')
                .then(async (response) => {
                    setFormData(formData.filter(data => data.form_id !== id));
                    toast.success(response.message);
                });
        }

    }

    console.log(allTicketFormMap, "allTicketFormMap big array")

    const renderForms = () => {
        const forms = [];
        if (false) {
            return pendingForm.map((player, index) => (
                <section key={index} className='m-4 mb-3'>
                    {/* Render form for each player response */}
                    <Row>
                        <Col sm={12}>
                            <h3>{tournamentDetails?.title}</h3>
                        </Col>
                        <Col sm={12}>
                            <div className='form-control mb-4 pt-3'>
                                <section className='d-flex justify-content-between align-items-center' onClick={togglePlayerDetails}>
                                    <h3> <img className='me-2 playerDetail_Icon' src={imageUrl + 'PlayerDetail_Icon.svg'} alt='PlayerDetail_Icon' />Player {index + 1} Details</h3>
                                    <div><img className='me-2 uparrow_Icon' src={imageUrl + 'UpArrow_Icon.svg'} alt='UpArrow_Icon' /></div>
                                </section>
                                <section className='m-1 player-detail-form'>
                                    {player?.map((item, index) => (
                                        <div key={index} className='my-4'>
                                            <div className='col-sm-12'>
                                                <div className="form-group">
                                                    <label htmlFor={item.label}>{item.label}{item.type === '7' || item.label === "Middle Name" ? null : <span class="text-danger">*</span>}</label>
                                                </div>
                                            </div>
                                            {/* Render form inputs based on item type */}
                                            {item.type === '2' ? (
                                                <select required onChange={(e) => handlePendingForm(e, index, item.label)} id={item.value} name={item.value} className="form-control" value={item.answer}>
                                                    {item?.options?.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option.name}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <>
                                                    {item.type === '3' && <input required className="form-control" type="text" id={item.value} name={item.value} defaultValue={item.answer} onChange={(e) => handlePendingForm(e, index, item.label)} />}
                                                    {item.type === '4' && <textarea required className="form-control" id={item.value} name={item.value} defaultValue={item.answer} onChange={(e) => handlePendingForm(e, index, item.label)} />}
                                                    {item.type === '5' && <input required className="form-control" type="number" id={item.value} name={item.value} defaultValue={item.answer} onChange={(e) => handlePendingForm(e, index, item.label)} />}
                                                    {item.type === '6' && <input required className="form-control" type="date" id={item.value} name={item.value} defaultValue={item.answer} style={{ width: "100%" }} onChange={(e) => handlePendingForm(e, index, item.label)} />}
                                                    {item.type === '7' &&
                                                        <div className="d-flex">
                                                            {/* <img className='default-img' src={item.answer} /> */}
                                                            <input className="form-control file-upload" accept=".png, .jpg, .jpeg" type="file" id={item.value} name={item.value} onChange={(e) => handleFileChange(e, item.label)} />
                                                        </div>
                                                    }
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <div className='col-sm-12 prview-check my-4'>
                                        <div className="form-group">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required onChange={(e) => checkedForm(e.target.checked, index)} />
                                                <label class="form-check-label mr-3" for="flexCheckDefault">
                                                    I agree & accept the rules, regulations, terms & conditions set by organizer's for the Tournament<span class="text-danger">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 d-flex justify-content-end'>
                                        <Button onClick={() => saveForm(index)} type="submit" variant='btn btn-primary'>
                                            Save
                                        </Button>
                                    </div>
                                </section>
                            </div>
                        </Col>
                    </Row>
                </section>
            ));
        } else if (formData && formData.formData !== null) {
            // Render forms from formData state if pendingForm is empty
            {
                for (let ticketId in allTicketFormMap) {
                    const ticketIndex = 0;
                    {
                        allTicketFormMap[ticketId]?.forms?.map((formData, formIndex) => {
                            forms.push(
                                <section key={formIndex} className='m-2 mb-3'>
                                    <>
                                        {formData && formData?.formData !== null && (
                                            <Row>
                                                <Col sm={12}>
                                                    <h3>{allTicketFormMap[ticketId].categoryName} ({allTicketFormMap[ticketId].name})</h3>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className='form-control mb-4'>
                                                        {formData?.saveForm && formData?.identifier === "team" ? null :
                                                            <section className='d-flex justify-content-between align-items-center' onClick={togglePlayerDetails}>
                                                                <h3> <img className='me-2 playerDetail_Icon' src={imageUrl + 'PlayerDetail_Icon.svg'} alt='PlayerDetail_Icon' />{formData?.identifier === "team" ? 'Team' : 'Player'} {formIndex + 1} Details</h3>
                                                                <div><img className='me-2 uparrow_Icon' src={imageUrl + 'UpArrow_Icon.svg'} alt='UpArrow_Icon' /></div>
                                                            </section>}
                                                        {formData?.saveForm && formData?.identifier === 'team' ?
                                                            <>
                                                                {/* render form for team player */}
                                                                {formData?.playerFormResponse?.map((_playerFormResponse, _playerFormResponseIndex) => {
                                                                    return (
                                                                        <div className='form-control mb-4'>
                                                                            <section className='d-flex justify-content-between align-items-center' onClick={togglePlayerDetails}>
                                                                                <h3><img className='me-2 playerDetail_Icon' src={imageUrl + 'PlayerDetail_Icon.svg'} alt='PlayerDetail_Icon' />Player {_playerFormResponseIndex + 1} Details</h3>
                                                                                <div><img className='me-2 uparrow_Icon' src={imageUrl + 'UpArrow_Icon.svg'} alt='UpArrow_Icon' /></div>
                                                                            </section>
                                                                            <section className='m-1 player-detail-form'>
                                                                                {_playerFormResponse?.formData?.map((item, index) => {
                                                                                    return (
                                                                                        <div key={index} className='my-4'>
                                                                                            <div className='col-sm-12'>
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={item.value}>{item.label}{item.isRequired === true ? <span class="text-danger">*</span> : null}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {item.type === '0' ? (
                                                                                                <div>
                                                                                                    {item?.options?.map((option, optionIndex) => (
                                                                                                        <div key={optionIndex} className="form-check">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={`${item.label}_${option.name}`}
                                                                                                                name={item.label}
                                                                                                                value={option.name}
                                                                                                                onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: _playerFormResponseIndex, ticketId, teamPlayerForm: true })}
                                                                                                                className="form-check-input"
                                                                                                                required
                                                                                                            />
                                                                                                            <label
                                                                                                                htmlFor={`${item.label}_${option.name}`}
                                                                                                                className="form-check-label"
                                                                                                            >
                                                                                                                {option.name}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {item.type === '2' ? (
                                                                                                        <>
                                                                                                            <select required onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: _playerFormResponseIndex, ticketId, teamPlayerForm: true })} id={item.value} name={item.value} className="form-control">
                                                                                                                <option value="" disabled selected>Select Option</option>
                                                                                                                {item?.options?.map((option, optionIndex) => (
                                                                                                                    <option key={optionIndex} value={option.name}>
                                                                                                                        {option.name}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {item.type === '3' && <input required className="form-control" type="text" id={item.value} name={item.value} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: _playerFormResponseIndex, ticketId, teamPlayerForm: true })} />}
                                                                                                            {item.type === '4' && <textarea required className="form-control" id={item.value} name={item.value} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: _playerFormResponseIndex, ticketId, teamPlayerForm: true })} />}
                                                                                                            {item.type === '5' && <input required className="form-control" type="number" id={item.value} name={item.value} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: _playerFormResponseIndex, ticketId, teamPlayerForm: true })} />}
                                                                                                            {item.type === '6' && <input required className="form-control" type="date" id={item.value} name={item.value} style={{ width: "100%" }} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: _playerFormResponseIndex, ticketId, teamPlayerForm: true })} />}
                                                                                                            {item.type === '7' && <input className="form-control file-upload" type="file" id={item.value} name={item.value} onChange={(e) => handleFileChange(e, item.label, index)} />}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                                <div className='col-sm-12 prview-check my-4'>
                                                                                    <div className="form-group">
                                                                                        <div class="form-check customCheck">
                                                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required onChange={(e) => checkedForm(e.target.checked, { ticketId, formIndex, playerFormIndex: _playerFormResponseIndex, teamPlayerForm: true })} />
                                                                                            <label class="form-check-label mr-3" for="flexCheckDefault">
                                                                                                I agree & accept the rules, regulations, terms & conditions set by organizer's for the Tournament<span class="text-danger">*</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-sm-12 d-flex justify-content-end'>
                                                                                    <Button onClick={() => saveForm({ ticketId, formIndex, playerFormIndex: _playerFormResponseIndex, teamPlayerForm: true })} type="submit" variant='btn btn-primary'>
                                                                                        Save
                                                                                    </Button>
                                                                                </div>
                                                                                {_playerFormResponse?.saveForm === true && formData?.playerFormResponse.length - 1 === _playerFormResponseIndex &&
                                                                                    <>
                                                                                        <div className='addmorePlayer'>
                                                                                            <button onClick={() => addMorePlayer({ ticketId, formIndex })} className=''><img src={imageUrl + 'addMore_icon.png'} alt='addMore_icon' />Add more Player</button>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </section>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            <>
                                                                {(formIndex === 0 || showPlayerDetails) && (
                                                                    <section className='m-1 player-detail-form'>
                                                                        {formData?.formData?.map((item, index) => {
                                                                            return (
                                                                                <div key={index} className='my-4'>
                                                                                    <div className='col-sm-12'>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor={item.value}>{item.label}{item.isRequired === true ? <span class="text-danger">*</span> : null}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.type === '0' ? (
                                                                                        <div>
                                                                                            {item?.options?.map((option, optionIndex) => (
                                                                                                <div key={optionIndex} className="form-check">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={`${item.label}_${option.name}`}
                                                                                                        name={item.label}
                                                                                                        value={option.name}
                                                                                                        onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: '', ticketId })}
                                                                                                        className="form-check-input"
                                                                                                        required
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={`${item.label}_${option.name}`}
                                                                                                        className="form-check-label"
                                                                                                    >
                                                                                                        {option.name}
                                                                                                    </label>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            {item.type === '2' ? (
                                                                                                <>
                                                                                                    <select required onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: '', ticketId })} id={item.value} name={item.value} className="form-control">
                                                                                                        <option value="" disabled selected>Select Option</option>
                                                                                                        {item?.options?.map((option, optionIndex) => (
                                                                                                            <option key={optionIndex} value={option.name}>
                                                                                                                {option.name}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {item.type === '3' && <input required className="form-control" type="text" id={item.value}
                                                                                                        // defaultValue={formIndex == 0 && formData?.identifier != "team" ? getUserFieldValue(item.value, userDetails?.name, userDetails) : ''} 
                                                                                                        name={item.value} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: '', ticketId })} />}
                                                                                                    {item.type === '4' && <textarea required className="form-control" id={item.value} name={item.value} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: '', ticketId })} />}
                                                                                                    {item.type === '5' && <input required className="form-control" type="number" id={item.value} name={item.value} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: '', ticketId })} />}
                                                                                                    {item.type === '6' && <input required className="form-control" type="date" id={item.value} name={item.value} style={{ width: "100%" }} onChange={(e) => handleInputChange(e, { formFieldIndex: index, defaultValue: '', formIndex, playerFormIndex: '', ticketId })} />}
                                                                                                    {item.type === '7' && <input className="form-control file-upload" type="file" id={item.value} name={item.value} onChange={(e) => handleFileChange(e, item.label, index)} />}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                        <div className='col-sm-12 prview-check my-4'>
                                                                            <div className="form-group">
                                                                                <div class="form-check customCheck">
                                                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required onChange={(e) => checkedForm(e.target.checked, { ticketId, formIndex, teamPlayerForm: false })} />
                                                                                    <label class="form-check-label mr-3" for="flexCheckDefault">
                                                                                        I agree & accept the rules, regulations, terms & conditions set by organizer's for the Tournament<span class="text-danger">*</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-12 d-flex justify-content-end'>
                                                                            {loader ?
                                                                                <Button disabled type="submit" variant='btn btn-primary'>
                                                                                    Uploading...
                                                                                </Button>
                                                                                :
                                                                                <Button onClick={() => saveForm({ ticketId, formIndex })} type="submit" variant='btn btn-primary'>
                                                                                    {formData?.identifier === "team" && !formData?.saveForm ? "Save & Next" : "Save"}
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                </section>
                            );
                        })
                    }
                }
            }
            return forms;
        }
    };

    return (
        <div className="custom-container detailFormouter">
            <div className='row'>
                <div className="backto-login mb-2 m-0">
                    <Link to="/my-booking/entryfee"><img src='../../../images/backArrow.svg' alt="icon" /> Back To Registration Fee</Link>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className='details-form'>
                        <Col sm={12}>
                            <div className='bottom_border mb-2' >
                                <h3 className="heading-space text-center">PLAYER'S DETAILS</h3>
                            </div>
                        </Col>
                        <div className='player-bottom'>
                            <Row className='p-2'>
                                <Col sm={6}>
                                    <h3>Tournament</h3>
                                    <p>{pendingTicket?.status === "pending" ? pendingTicket?.event_name : tournamentDetails?.title}</p>
                                </Col>
                                <Col sm={6} className='d-flex justify-content-end' >
                                    <div>
                                        <h3>Start - end Date</h3>
                                        <p>{formattedStartDate} - {formattedEndDate}</p>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <h3>Venue</h3>
                                    <p>{pendingTicket?.status === "pending" ? pendingTicket?.address : tournamentDetails?.address}, {pendingTicket?.status === "pending" ? pendingTicket?.city : tournamentDetails?.city}</p>
                                </Col>
                                <Col sm={6} className='d-flex justify-content-end'>
                                    <div>
                                        <h3>Timings</h3>
                                        <p>{formattedStartTime} - {formattedEndTime}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {renderForms()}
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 detailCont">
                    <ContactOrganizer
                        detailsForm={"hide"}
                        disabled={"disabled"}
                        summary={true}
                        quantities={pendingTicket?.status === "pending" ? pendingTicket?.booking_pending_player_response?.length : ticketDetails?.quantities}
                        totalEntry={pendingTicket?.status === "pending" ? pendingTicket?.ticketPrice : ticketDetails?.totalEntry}
                        btnData={
                            (pendingTicket?.status === "pending" ? ticketAvailable?.status : pendingForm?.length > 0 || submitForm && !emptyAnswer && checkedBox)
                                ? false
                                : true
                        }
                        bookTicket={bookTicketInitiate}
                        disCountValue={ticketAvailable}
                    />
                </div>
            </div>
        </div>
    )
}

export default DetailsForm;
