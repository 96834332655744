import React, { useEffect, useState } from "react";
import { Button, Col, Container } from "react-bootstrap";
import ContactOrganizer from "./components/contact";
import Api from "../../api/Api";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setTicketDetails } from "../../store/ticketDetailsSlice";
import { toast } from "react-toastify";
import { updateTicketPayload } from "../../redux/actions";

const imageUrl = '../../.././images/';

const EntryFee = () => {
    const dispatch = useDispatch();
    const userID = JSON.parse(localStorage.getItem('userID'));
    const ticketPayloadData = useSelector(state => state.TicketPayload);
    const [ticketPayload, setTicketPayload] = useState(ticketPayloadData);
    const isLoggedIn = localStorage.getItem("isLoggedIn")
    const tournamentId = localStorage.getItem("selectedTournamentId");
    const parseTournamentDetails = localStorage.getItem("tournamentDetails");
    const tournamentDetails = JSON.parse(parseTournamentDetails);
    const [ticketData, setTicketData] = useState();
    const [quantities, setQuantities] = useState([]);
    const [totalPrize, setTotalPrize] = useState(0);
    const [ticketAvailable, setTicketAvailable] = useState(JSON.parse(localStorage.getItem('ticketAvailability')) || false);
    const [ticketQuantity, setTicketQuantity] = useState([]);
    const [ticketsArray, setTicketsArray] = useState([]);
    const [couponValue, setCouponValue] = useState("");
    const [enable, setEnable] = useState(false);
    const [previousResponse, setPreviousResponse] = useState(null);
    const [hasCheck, setHasCheck] = useState(false);
    const handleApplyCoupon = (coupon) => {
        console.log(coupon, "coupon in function")
        setCouponValue(coupon)
        setEnable(true)
    }



    const handleAddClick = (index, price, id, name, categoryId, detail) => {
        if (tournamentDetails?.status === 0) {
            toast.error("No tickets available for registration at the moment!");
            return;
        }

        const newQuantities = [...quantities];
        newQuantities[index] += 1;
        const totalQuantity = newQuantities.reduce((acc, curr) => acc + curr, 0);
        setQuantities(newQuantities);
        setTicketQuantity(totalQuantity)
        updateTotalPrize(newQuantities);
        checkTicketAvailable(newQuantities[index], id, price, name, categoryId, detail,);
        const updatedTicketsArray = [...ticketsArray];
        const ticketIndex = updatedTicketsArray.findIndex(ticket => ticket.id === id);
        if (ticketIndex !== -1) {
            updatedTicketsArray[ticketIndex].quantity = newQuantities[index];
        } else {
            updatedTicketsArray.push({
                id: id,
                quantity: newQuantities[index],
            });
        }
        const totalEntry = calculateTotalEntry(newQuantities, ticketData);
        const newTicketDetails = {
            quantities: totalQuantity,
            totalEntry: totalEntry.toFixed(2),
            ticketData: ticketData,
            ticketId: id,
            ticketName: name,
            ticketArray: JSON.stringify(ticketsArray && ticketsArray),
        };
        dispatch(setTicketDetails(newTicketDetails));
        localStorage.setItem('ticketData', JSON.stringify(newTicketDetails));
    };

    // Function to calculate total entry dynamically
    const calculateTotalEntry = (quantities, ticketData) => {
        let total = 0;
        for (let i = 0; i < quantities.length; i++) {
            total += quantities[i] * ticketData[i].price;
        }
        return total;
    };

    const handleRemoveClick = (index, price, id, name, categoryId, detail) => {
        setHasCheck(false);
        if (quantities[index] > 0) {
            const newQuantities = [...quantities];
            newQuantities[index] -= 1;
            const totalQuantity = newQuantities.reduce((acc, curr) => acc + curr, 0);
            setQuantities(newQuantities);
            setTicketQuantity(totalQuantity)
            updateTotalPrize(newQuantities);
            if (newQuantities[index] !== -1) {
                checkTicketAvailable(newQuantities[index], id, price, name, categoryId, detail);
            }
            let updatedTicketsArray = [...ticketsArray];
            const ticketIndex = updatedTicketsArray.findIndex(ticket => ticket.id === id);
            if (ticketIndex !== -1) {
                if (newQuantities[index] > 0) {
                    updatedTicketsArray[ticketIndex].quantity = newQuantities[index];
                } else {
                    updatedTicketsArray = updatedTicketsArray.filter(ticket => ticket.id !== id); // Remove the ticket if quantity is 0
                }
            }

            // Filter out tickets with quantity 0
            updatedTicketsArray = updatedTicketsArray.filter(ticket => ticket.quantity > 0);

            if (totalQuantity == 0) {
                setCouponValue("")
                localStorage.removeItem('couponValue')
            }
            const totalEntry = calculateTotalEntry(newQuantities, ticketData);
            const newTicketDetails = {
                quantities: totalQuantity,
                totalEntry: totalEntry.toFixed(2),
                ticketData: ticketData,
                ticketId: id,
                ticketName: name,
                ticketArray: JSON.stringify(updatedTicketsArray),
            };
            dispatch(setTicketDetails(newTicketDetails));
            localStorage.setItem('ticketData', JSON.stringify(newTicketDetails));
        }
    };

    const updateTotalPrize = (newQuantities) => {
        let total = 0;
        ticketData?.forEach((data, index) => {
            total += newQuantities[index] * data.price;
        });
        setTotalPrize(total);
    };

    useEffect(() => {
        if (couponValue && enable) {
            if (ticketQuantity > 0) {
                checkTicketAvailable();
                setEnable(false)
            }
        }
        localStorage.setItem('ticketAvailability', JSON.stringify(ticketAvailable));
        if (isLoggedIn) {
            console.log(isLoggedIn,"-fsdjkfsdj")
            checkTicketAvailable();
        }
    }, [couponValue, ticketAvailable]);

    useEffect(() => {
        getTicket();
    }, []);

    // api call for check ticket
    const checkTicketAvailable = (newQuantities, id, price, name, categoryId, detail) => {
        // if user is logged in then do this 
        if (isLoggedIn) {
            setTicketsArray(ticketPayload?.tickets);
            setTicketQuantity(ticketPayload?.tickets?.length);
        }
        const ticketIndex = ticketsArray?.findIndex(ticket => ticket.id === id);
        if (ticketIndex !== -1) {
            ticketsArray[ticketIndex].quantity = newQuantities;
        } else {
            ticketsArray.push({
                id: id,
                quantity: newQuantities,
                ticketName: name,
                categoryId: categoryId,
                detail: detail,
                categoryName: detail?.categoryName
            });
        }
        const filteredTicketsArray = couponValue ? ticketsArray.filter(ticket => ticket.id) : ticketsArray;
        const params = {
            tickets: filteredTicketsArray,
            paymentMethod: price > 0 ? "online" : "online",
            eventId: tournamentDetails?.id,
            bookingDate: moment(tournamentDetails?.start_date).format('YYYY-MM-DD'),
            startTime: tournamentDetails?.start_time,
            endTime: tournamentDetails?.end_time,
        }
        if (couponValue) {
            params.couponCode = couponValue;
        }
        dispatch(updateTicketPayload(params));
        setHasCheck(true);
        let data = isLoggedIn ? ticketPayload : params;
        if (userID) {
            Api.call(`checkBookingTicketAvailable`, 'POST', data)
                .then(async (response) => {
                    setTotalPrize(response?.totalPrice)
                    if (response.status === false) {
                        if (previousResponse) {
                            setTicketAvailable(previousResponse);
                        } else {
                            setTicketAvailable(response);
                        }
                        toast.error(response.error || response.message);
                        if (response.couponInvalid) {
                            setCouponValue("")
                        }


                    } else {
                        setTicketAvailable(response);
                        setPreviousResponse(response);
                        if (couponValue && enable && response.discountDetails && response.discountDetails.couponApplied) {
                            localStorage.setItem("couponValue", couponValue);
                            toast.success("Coupon applied successfully!");
                        }
                    }
                    localStorage.removeItem("isLoggedIn");
                })
                .catch(error => {
                    if (previousResponse) {
                        setTicketAvailable(previousResponse);
                    }
                    console.error("API Error:", error);
                });
        }
    }

    // api call for get ticket
    const getTicket = async () => {
        await Api.call(`tickets?eventId=${tournamentId}`, "GET")
            .then(async (response) => {
                setTicketData(response);
                if (isLoggedIn) {
                    const updatedQuantities = response?.map(ticket => {
                        const payloadTicket = ticketPayload?.tickets?.find(payloadTicket => payloadTicket.id === ticket.id);
                        return payloadTicket ? payloadTicket.quantity : 0;
                    });
                    setQuantities(updatedQuantities);
                } else {
                    setQuantities(new Array(response.length).fill(0));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // date and time format
    const startDate = moment(tournamentDetails?.start_date);
    const startTime = moment(tournamentDetails?.start_time, "HH:mm");

    // Parse the end date and time strings using moment
    const endDate = moment(tournamentDetails?.end_date);
    const endTime = moment(tournamentDetails?.end_time, "HH:mm");

    // Format the start and end date and time strings
    const formattedStartDate = startDate.format("D MMM YYYY");
    const formattedStartTime = startTime.format("hh:mm A");

    const formattedEndDate = endDate.format("D MMM YYYY");
    const formattedEndTime = endTime.format("hh:mm A");

    return (
        <>
            <div className="row mt-2">
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className='publish-inner-container' >
                        <table className="checkoutTable" width="100%">
                            <tbody>
                                <tr>
                                    <th width="100%" colspan="2" className="detailsCheckout text-center">CHECKOUT</th>
                                </tr>
                                <tr>
                                    <td>
                                        <label>TOURNAMENT</label>
                                        <p>{tournamentDetails?.title}</p>
                                    </td>
                                    <td align="right">
                                        <label>START - END DATE</label>
                                        <p>{formattedStartDate} - {formattedEndDate}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>VENUE</label>
                                        <p>{tournamentDetails?.address},{tournamentDetails?.city}</p>
                                    </td>
                                    <td align="right">
                                        <label>TIMINGS</label>
                                        <p>{formattedStartTime} - {formattedEndTime}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="entryTable" width="100%">
                            <tbody>
                                <tr>
                                    <th width="100%" colspan="3" className="detailsCheckout text-center">REGISTRATION FEE</th>
                                </tr>
                                {ticketData && ticketData?.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="40%">
                                                <label>{data?.categoryName} ({data?.title})</label>
                                                <p>{data?.price} INR</p>
                                                {/* <p>{data?.description}</p> */}
                                            </td>
                                            <td width="20%" align="center">
                                                {quantities[index] > 0 || isLoggedIn ? (
                                                    <div className="scoreCount">
                                                        <button className="minusBtn" onClick={() => handleRemoveClick(index, data.price, data.id, data.title)}>
                                                            <img className="minusimg" src={imageUrl + "minus.svg"} alt="minus Icon" />
                                                        </button>
                                                        <span className="quantity-display">{quantities[index]}</span>
                                                        <button
                                                            className="plusBtn"
                                                            onClick={() => handleAddClick(index, data.price, data.id, data.title, data.category_id, data)}
                                                            disabled={
                                                                ticketAvailable?.status === false ||
                                                                (ticketData && ticketData.find(ticket => ticket.id === data.id)?.limit_per_player === quantities[index])
                                                            }>
                                                            <img className="plusimg" src={imageUrl + "plus.svg"} alt="plus Icon" />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button onClick={() => handleAddClick(index, data.price, data.id, data.title, data.category_id, data)} type="button" className="ticketAdd">
                                                        <img src="../../../images/plusIcon.svg" alt="Add" />
                                                        ADD
                                                    </button>
                                                )}
                                            </td>
                                            <td width="40%" align="right"><label>{(quantities[index] * data.price).toFixed(2)} INR</label></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <ContactOrganizer
                        disabled={hasCheck === true ? false : "disabled"}
                        summary={ticketAvailable?.status && quantities?.some((qty) => qty > 0)}
                        quantities={ticketQuantity}
                        totalEntry={totalPrize?.toFixed(2)}
                        handleApplyCoupon={handleApplyCoupon}
                        disCountValue={ticketAvailable}
                    // coupon={coupon}
                    />
                </div>
            </div>
        </>
    )
}

export default EntryFee;