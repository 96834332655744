import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import ReactSelect, { components } from 'react-select';
import Api from '../../api/Api';
import { toast } from "react-toastify";

const OrganizerForm = ({ showModal, onClose }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        company: '',
        contact: '',
        feature: [],
        message: ''
    });

    const [errors, setErrors] = useState({
        contact: '',
        email: ''
    });

    // options
    const queryOptions = [
        { value: 'tournamentManagement', label: 'Tournament Management' },
        { value: 'scoringSystem', label: 'Scoring System' },
        { value: 'creativeMaking', label: 'Creative Making' },
        { value: 'certificateGeneration', label: 'Certificate Generation' }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Validation checks
        if (name === 'contact') {
            const isValidPhone = /^\d{10,}$/.test(value); // Minimum 10 digits
            setErrors({
                ...errors,
                contact: isValidPhone || value === '' ? '' : 'Phone number must be at least 10 digits.'
            });
        }

        if (name === 'email') {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Email pattern
            setErrors({
                ...errors,
                email: isValidEmail || value === '' ? '' : 'Invalid email format.'
            });
        }
    };

    const handleSelectChange = (selectedOption) => {
        setFormData(prevData => ({ ...prevData, feature: selectedOption }));
    };

    const isFormValid = () => {
        return formData.fullName && !errors.email &&
            !errors.contact && formData.feature.length > 0 && formData.message;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const param = {
            "title": formData.feature.map(feature => feature.label).join(', '),
            "name": formData.fullName,
            "email": formData.email,
            "message": formData.message,
            "company": formData.company,
            "phone": formData.contact,
            "requestFor": "featureAndPricing"
        }
        Api.CoreApi("contactUs", "POST", param)
            .then((response) => {
                if (response && response[0].accepted.length > 0) {
                    toast.success("Email sent successfully");
                    onClose();
                } else {
                    toast.error("Email not sent");
                }
            })
            .catch((error) => {
                toast.error("An error occurred while sending the email");
            })
    };

    return (
        <Modal className="organizer-modal" show={showModal} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Contact to Zemo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group controlId="fullName">
                                <Form.Label className="custom-label">Full Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your full name"
                                    name="fullName"
                                    className="custom-placeholder"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group controlId="company">
                                <Form.Label className="custom-label">Company / Organization Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your company or organization name"
                                    name="company"
                                    className="custom-placeholder"
                                    value={formData.company}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group controlId="email">
                                <Form.Label className="custom-label">Email<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email address"
                                    name="email"
                                    className="custom-placeholder"
                                    value={formData.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group controlId="contact">
                                <Form.Label className="custom-label">Contact Number<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter contact number"
                                    name="contact"
                                    className="custom-placeholder"
                                    value={formData.contact}
                                    onChange={handleChange}
                                    isInvalid={!!errors.contact}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.contact}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="feature">
                                <Form.Label className="custom-label">Select feature<span className="text-danger">*</span></Form.Label>
                                <ReactSelect
                                    options={queryOptions}
                                    placeholder="Select feature for the query"
                                    isClearable
                                    isMulti
                                    onChange={handleSelectChange}
                                    value={formData.feature}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isSearchable={false}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="message">
                                <Form.Label className="custom-label">Message<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Details about the query"
                                    name="message"
                                    className="custom-placeholder"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!isFormValid()}
                >
                    Send Message
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OrganizerForm;