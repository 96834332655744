import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import ContactOrganizer from './components/contact';
import Api from '../../api/Api';
import moment from "moment";
import config from '../../config/config';
import { useDispatch } from 'react-redux';
import { getTournamentType } from '../../store/tournamentTypeSlice';

const PlayerDetails = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const env = process.env.REACT_APP_ENV || "production";
    let BASE_URL = config[env].IMG_URL;
    const imageUrl = '../../.././images/';
    const tournamentId = localStorage.getItem("selectedTournamentId");
    const [tournamentDetails, setTournamentDetails] = useState();
    const [entryFee, setEntryFee] = useState();
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [tournamentView, setTournamentView] =useState()


    const handleCloseModal = () => setShowModal(false);

    const [imageError, setImageError] = useState(false);
    const handleImageError = () => {
        setImageError(true);
    };

    const removePTag = (htmlString) => {
        return htmlString?.replace(/<p>/g, '')?.replace(/<\/p>/g, '')?.replace(/&nbsp;/g, '');
    };


    // api call for show entry fee
    const getTicket = async () => {
        if (isNaN(tournamentId)) {
            console.log("Invalid tournamentId");
            return;
        }
        await Api.call(`tickets?eventId=${tournamentId == '697' ? '698' : tournamentId}`, "GET")
            .then(async (response) => {
                const minPrice = response?.length == 0 ? null : Math.min(...response?.map(ticket => Number(ticket.price)));
                setEntryFee(minPrice);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        const now = moment();
        if (tournamentView) {
            console.log(tournamentView, "tournamentView")
            const now = moment(); // Current date and time
            const startDate = moment(tournamentView.start_date, "YYYY-MM-DD");
            const endDate = moment(tournamentView.end_date, "YYYY-MM-DD");
            const startTime = moment(tournamentView.start_time, "HH:mm:ss");
            const endTime = moment(tournamentView.end_time, "HH:mm:ss");
            if (now.isBefore(startDate)) {
                localStorage.setItem("selectedTournamentType", "upComing");
                dispatch(getTournamentType("upComing"))
            } else if (now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate)) {
                // On the start date or between start and end dates
                if (now.isBefore(moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`))) {
                    // Before start time on the start date
                    localStorage.setItem("selectedTournamentType", "upComing");
                    dispatch(getTournamentType("upComing"))
                } else if (now.isBetween(
                    moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`),
                    moment(`${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`),
                    undefined,
                    "[]"
                )) {
                    localStorage.setItem("selectedTournamentType", "onGoing");
                    dispatch(getTournamentType("onGoing"))
                } else if (now.isAfter(moment(`${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`))) {
                    localStorage.setItem("selectedTournamentType", "onGoing");
                    dispatch(getTournamentType("onGoing"))
                }
            } else if (now.isAfter(endDate)) {
                localStorage.setItem("selectedTournamentType", "recentlyConclud");
                dispatch(getTournamentType("recentlyConclud"))
            }
        }
    }, [tournamentView, dispatch]);

    
    useEffect(() => {
        getTournamentDetails();
        if(tournamentId){
            getTicket();
        }
       
    }, []);


    // api call for details
    const getTournamentDetails = async () => {
        let idToUse;

        // Check if tournamentId is set

        // If tournamentId is not set, extract it from the URL
        const pathSegments = location.pathname.split('/');
        idToUse = pathSegments[pathSegments.length - 1];

        //if idToUse is null then get before /
        if (!idToUse) {
            idToUse = pathSegments[pathSegments.length - 2];
        }
        if(idToUse !== 'id'){
            localStorage.setItem("selectedTournamentId", idToUse);
        }else if (tournamentId === null || tournamentId === 'undefined' || idToUse === 'id') {
            idToUse = localStorage.getItem("selectedTournamentId");
        }

        // If no tournament ID is found, exit the function
        if (!idToUse) {
            console.error("No tournament ID available.");
            return;
        }

        // Proceed with API call
        await Api.call(`tournamentDetails?id=${idToUse == '697' || idToUse == 'xperia-prime-kids-league' ? '698' : idToUse}`, "GET")
            .then(async (response) => {
                setTournamentDetails(response);
                const responseJSON = JSON.stringify(response);
                localStorage.setItem("tournamentDetails", responseJSON);
                localStorage.setItem("setCardInfo", responseJSON);
                setTournamentView(response)
                localStorage.setItem("selectedTournamentId", response.id);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // date and time format
    const startDate = moment(tournamentDetails?.start_date);
    const startTime = moment(tournamentDetails?.start_time, "HH:mm");

    // Parse the end date and time strings using moment
    const endDate = moment(tournamentDetails?.end_date);
    const endTime = moment(tournamentDetails?.end_time, "HH:mm");

    // Format the start and end date and time strings
    const formattedStartDate = startDate.format("D MMM YYYY");
    const formattedStartTime = startTime.format("h:mm A");

    const formattedEndDate = endDate.format("D MMM YYYY");
    const formattedEndTime = endTime.format("h:mm A");

    // html remove
    const removePTags = (htmlString) => {
        return htmlString?.replace(/<p>/g, '')?.replace(/<\/p>/g, '');
    };

    const formattedDescription = removePTags(tournamentDetails?.description);

    const openMapInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    return (
        <>
            {tournamentDetails &&
                <div className="row detailOuter">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className='publish-inner-container'>
                            {tournamentDetails?.sponsors?.length > 0 && (
                                <section className='sponsor-container d-flex mb-3'>
                                    <div className='rotate-text me-3'>
                                        <h3>Partners</h3>
                                    </div>
                                    <div className='mt-1 row'>
                                        {
                                            tournamentDetails?.sponsors?.map((data) => {
                                                return (
                                                    <div className='galleryImg col'>
                                                        <img className='me-3 w-100' onError={handleImageError} src={data?.image.startsWith('http') ? data?.image : BASE_URL + data?.image} alt='add_Icon' />
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                </section>
                            )}
                            {/* detail preview */}
                            <Row>
                                <Col sm={12}>
                                    <div className='d-flex me-3 tournamentDetailsOuter' >
                                        <div className='category-outer'>
                                            <img
                                                onClick={() => handleImageClick(tournamentDetails.thumbnail && tournamentDetails.thumbnail !== undefined && tournamentDetails.thumbnail !== "undefined"  ? tournamentDetails.thumbnail : tournamentDetails.poster)}
                                                style={{ cursor: 'pointer' }}
                                                className='me-3'
                                                src={tournamentDetails.thumbnail && tournamentDetails.thumbnail !== undefined && tournamentDetails.thumbnail !== "undefined"  ? tournamentDetails.thumbnail : tournamentDetails.poster}
                                                alt='add_Icon'
                                            />
                                        </div>
                                        <div>
                                            <div className='detialsLoction'>
                                                <h2 style={{ fontSize: '22px' }}>{tournamentDetails?.title}</h2>
                                                <div className='mb-2'>
                                                    <div className='d-flex mb-2 mt-2 align-items-center'>
                                                        <span className='iconouter ms-0'>
                                                            <img src={imageUrl + 'date_picker_Icon.svg'} alt='add_Icon' />
                                                        </span>
                                                        <div>
                                                            <span>From {formattedStartDate} To {formattedEndDate}</span>
                                                            <span> | {formattedStartTime} To {formattedEndTime}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center mb-2'>
                                                    <span className='iconouter ms-0'>
                                                        <img src={imageUrl + 'locationIcon.svg'} alt='locationIcon' />
                                                    </span>
                                                    <span>{tournamentDetails?.address && tournamentDetails?.address?.charAt(0)?.toUpperCase() + tournamentDetails?.address?.slice(1)} {tournamentDetails?.city && tournamentDetails?.city?.charAt(0)?.toUpperCase() + tournamentDetails?.city?.slice(1)}
                                                        {/* {tournamentDetails?.venueDetails?.slug &&
                                                            <Link style={{ display: 'inline-block', paddingLeft: '5px' }} className='map-link' onClick={() => openMapInNewTab(tournamentDetails?.venueDetails?.slug)}>
                                                                Google Map Link
                                                            </Link>
                                                        } */}
                                                    </span>
                                                </div>
                                                {tournamentDetails?.isExternal === 1 ?
                                                    null
                                                    :
                                                    <>
                                                        {entryFee === null ? null :
                                                            <div className='d-flex align-items-center'>
                                                                <span className='iconouter ms-0'>
                                                                    <img src={imageUrl + 'entry-fee.svg'} alt='FeeIcon' />
                                                                </span>
                                                                <span>{entryFee}</span>
                                                            </div>}
                                                    </>
                                                }
                                                {/* <div className='map-link'><Link>{tournamentDetails?.slug}</Link></div> */}
                                            </div>

                                        </div>
                                    </div>


                                </Col>
                                {tournamentDetails.facebooklink || tournamentDetails.instagramlink || tournamentDetails.twitterlink || tournamentDetails.linkedInlink || tournamentDetails.whatsAppgrouplink ?
                                    <Row className='socialInfo'>
                                        <h3>Tournament's Social Media Links</h3>
                                        <div className='socialInfoBG'>
                                            {tournamentDetails.facebooklink &&
                                                <div className='socialicons'>
                                                    <Link target="_blank" to={tournamentDetails.facebooklink} className='iconouter'>
                                                        <img src={imageUrl + "socialicons/facebook-icon.svg"} />
                                                    </Link>
                                                </div>
                                            }
                                            {tournamentDetails.instagramlink &&
                                                <div className='socialicons'>
                                                    <Link target="_blank" to={tournamentDetails.instagramlink} className='iconouter'>
                                                        <img src={imageUrl + "socialicons/instagram-icon.svg"} />
                                                    </Link>
                                                </div>
                                            }
                                            {tournamentDetails.twitterlink &&
                                                <div className='socialicons'>
                                                    <Link target="_blank" to={tournamentDetails.twitterlink} className='iconouter'>
                                                        <img src={imageUrl + "socialicons/twitter-icon.svg"} />
                                                    </Link>
                                                </div>
                                            }
                                            {tournamentDetails.linkedInlink &&
                                                <div className='socialicons'>
                                                    <Link target="_blank" to={tournamentDetails.linkedInlink} className='iconouter'>
                                                        <img src={imageUrl + "socialicons/in-icon.svg"} />
                                                    </Link>
                                                </div>
                                            }
                                            {tournamentDetails.whatsAppgrouplink &&
                                                <div className='socialicons'>
                                                    <Link target="_blank" to={tournamentDetails.whatsAppgrouplink} className='iconouter'>
                                                        <img src={imageUrl + "socialicons/whatsApp-icon.svg"} />
                                                    </Link>
                                                </div>
                                            }

                                        </div>

                                    </Row>
                                    : ""}

                                <Col sm={12}>
                                    <p className="mt-3">{removePTags(tournamentDetails?.excerpt)}</p>
                                </Col>
                                {tournamentDetails?.description &&
                                    <Col sm={12}>
                                        <h3>Description</h3>
                                        <p className='descriptiontag mt-2' dangerouslySetInnerHTML={{ __html: removePTag(formattedDescription) }}></p>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <ContactOrganizer />
                    </div>
                </div>
            }
            <Modal className='preview-logo' show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <img src={selectedImage} alt="preview_image" className="img-fluid" />
            </Modal>
        </>
    )
}

export default PlayerDetails;