import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ContactModal from "../../modal/contactOrganizer";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DicountConditions from "../../modal/dicountConditions";
import Api from "../../../api/Api";


const ContactOrganizer = ({ disabled, summary, detailsForm, quantities, totalEntry, btnData, bookTicket, handleApplyCoupon, disCountValue }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const parseTournamentDetails = localStorage.getItem("tournamentDetails");
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const tournamentDetails = JSON.parse(parseTournamentDetails);
    const pendingTicketDetails = localStorage.getItem('pendingTicket');
    const pendingTicket = pendingTicketDetails ? JSON.parse(pendingTicketDetails) : null;
    const [showModal, setShowModal] = useState(false);
    const [showConditionsModal, setShowConditionsModal] = useState(false);
    const [couponValue, setCouponValue] = useState("");
    const [couponCode, setCouponCode] = useState(false);
    const [discountConditions, setDiscountConditions] = useState("")
    const [isScrolled, setIsScrolled] = useState(false);
    const [isload, setIsLoad] = useState(true);

    const hidedisCountcoupon = () => {
        return location.pathname.includes('/details-form');
    };
    const handleContactClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleRegisterClick = () => {
        if (!userDetails) {
            if (location.pathname === '/my-booking/entryfee') {
                localStorage.setItem("isLoggedIn", "isLoggedIn")
            }
            localStorage.setItem('createdUrl', window.location.href);
            navigate("/register");
            return;
        } else {
            navigate("/my-booking/entryfee");
            window.location.reload();
        }
    }

    const openConditionsModal = () => {
        setShowConditionsModal(true)
    }
    const closeConditionsModal = () => {
        setShowConditionsModal(false)
    }

    const couponHandle = (e) => {
        setCouponValue(e.target.value)
    }

    const handleApply = () => {
        if (!couponValue) {
            toast.error("Please enter a coupon code");
            return;
        }
        handleApplyCoupon(couponValue);
    };


    useEffect(() => {
        if (isload == true) {
            const fetchDiscountConditions = async () => {
                try {
                    const res = await Api.call(`discounts?tournamentId=${tournamentDetails.id}`);
                    if (res.length > 0) {
                        setCouponCode(res[0].code);
                        setDiscountConditions(res[0].hasTC)
                    }
                } catch (error) {
                    console.error('Error fetching discount conditions:', error);
                }
            };
            fetchDiscountConditions();
            setIsLoad(false)
        }

    }, [tournamentDetails, discountConditions], []);


    useEffect(() => {
        const handleScroll = () => {
            const isBottom = (window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - 10);
            setIsScrolled(isBottom);
        };
        const handleTouchMove = () => {
            const isBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
            setIsScrolled(isBottom);
        };
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchmove', handleTouchMove);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);


    const tournamentType = localStorage.getItem("selectedTournamentType");
    return (
        <>
            {tournamentType === "upComing" &&
                <>
                    <div className="shareCard-outer">
                        <div className="shareCard registerOuter">
                            <div className="p-2 reInner">
                                <div className="d-flex justify-content-between">
                                    <sapn className="shareCard-text">{pendingTicket?.status === "pending" ? pendingTicket?.event_name : tournamentDetails?.title}</sapn>
                                    {/* <img src="../../.././images/shareIcon.svg" /> */}
                                </div>
                                <span>#{pendingTicket?.status === "pending" ? pendingTicket?.event_category : tournamentDetails?.event_category}</span>
                            </div>
                            <div className="checkout-register-btn">
                                {summary ? (
                                    <>
                                        <div className="d-flex justify-content-center summary">
                                            <h3>SUMMARY</h3>
                                        </div>
                                        <div className="p-3">
                                            <div className="ticketSummary">
                                                <div className="d-flex justify-content-between mb-1">
                                                    <span>Total Enrollments</span>
                                                    <span>{disCountValue?.bookings && disCountValue?.bookings.length}</span>
                                                </div>

                                                {disCountValue?.discountDetails && disCountValue?.totalPrice ?
                                                    <>
                                                        <div className="d-flex justify-content-between  mb-1">
                                                            <span>Total Registration Fee</span>
                                                            <span>{disCountValue.totalPrice > 0 ? `${disCountValue.totalPrice + disCountValue.discountDetails.totalDiscountGiven} INR` : "Free"}</span>
                                                        </div>
                                                        <div className="discountValue  mb-1">
                                                            <label>Discount</label>
                                                            <span className="value">-{disCountValue.discountDetails.totalDiscountGiven} INR</span>
                                                        </div>
                                                        <div className="totalValue">
                                                            <label>Total</label>
                                                            <span className="value">{disCountValue.totalPrice} INR</span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="d-flex justify-content-between  mb-1">
                                                            <span>Total Registration Fee</span>
                                                            <span>{disCountValue.totalPrice > 0 ? `${disCountValue.totalPrice} INR` : "Free"}</span>
                                                        </div>
                                                        {(hidedisCountcoupon() || (!couponCode) || (quantities.length > 1)) ?
                                                            ""
                                                            : <>
                                                                <div className="addCoupon">
                                                                    <input onChange={couponHandle} className="form-control" placeholder="Enter Discount Code" />
                                                                    <button onClick={handleApply} disabled={!couponValue
                                                                    } className="btn green-btn-primary">Apply</button>
                                                                </div>
                                                                <button className="conditionsLink" onClick={openConditionsModal}>Conditions and Restrictions</button>
                                                            </>}
                                                    </>
                                                }

                                            </div>


                                            <Link
                                                to={btnData ? "/details-form" : "/details-form"}
                                                type="button"
                                                className={`btn btn-primary w-100 ${btnData ? "disabled" : ""}`}
                                                disabled={btnData}
                                                onClick={btnData ? null : bookTicket}
                                            >
                                                Proceed
                                            </Link>

                                        </div>
                                    </>
                                ) : (
                                    <div className="p-3">
                                        {/* for mobile view */}
                                        <button disabled={disabled} className={`btn btn-primary w-100 ${disabled ? "disabled" : ""}`} onClick={handleRegisterClick}>
                                            Register Now
                                        </button>
                                    </div>
                                )}
                            </div>

                        </div>
                        {detailsForm !== "hide" && (
                            <div className="shareCard contactOrganizer mt-2">
                                <div className="p-2 d-flex">
                                    <img src="../../.././images/contactUser.svg" alt="Contact User" />
                                    <div className="contact-btn">
                                        <span className="contact-text">If you have a question regarding the tournament, contact the organizer.</span>
                                        <Button onClick={handleContactClick} type="button" className="btn btn-primary mt-2">Contact Organizer</Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <ContactModal show={showModal} onHide={handleCloseModal} />
                        {hidedisCountcoupon() ? null :
                            <DicountConditions discountConditions={discountConditions} tournamentDetails={tournamentDetails} showConditionsModal={showConditionsModal} handleClose={closeConditionsModal} />
                        }
                    </div>

                    {/* Register Now button for mobile view only   */}
                    <div className={isScrolled ? "proceedForMobile hide" : "proceedForMobile"}>
                        <Link
                            to={btnData ? "/details-form" : "/details-form"}
                            type="button"
                            className={`btn btn-primary w-100 ${btnData ? "disabled" : ""}`}
                            disabled={btnData}
                            onClick={btnData ? null : bookTicket}
                        >
                            Proceed
                        </Link>
                    </div>
                    {!summary &&
                        <div className={isScrolled ? "proceedForMobile hide" : "proceedForMobile"}>
                            <button disabled={disabled} className={`btn btn-primary w-100 ${disabled ? "disabled" : ""}`} onClick={handleRegisterClick}>
                                Register Now
                            </button>
                        </div>
                    }
                </>
            }
        </>

    )
}

export default ContactOrganizer;