export const updateTicketPayload = (data) => {
    return {
        type: 'UPDATE_TICKET_PAYLOAD',
        data,
    }
}
export const updateProfileImg = (data) => {
    return {
        type: 'UPDATE_PROFILE_IMG',
        data,
    }
}