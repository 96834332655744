import { React, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Api from '../../api/Api';


function DicountConditions({ showConditionsModal, handleClose, tournamentDetails, discountConditions }) {

   useEffect(() => {
       
    }, [showConditionsModal, tournamentDetails,discountConditions]);


    return (
        <Modal
            className="md-custom"
            show={showConditionsModal}
            onHide={handleClose}
            centered
            size="md"
        >
            <Modal.Header closeButton>
                <div className='communication_header'>
                    Discount Conditions and Restrictions
                </div>

            </Modal.Header>
            <Modal.Body>
                <div className='condition-icon'>
                    <img src="../../.././images/condition-icon.svg" />
                </div>
                <p>Welcome to <label className='titleLabel'>{tournamentDetails.title}!</label>  Before you apply a discount code, take a moment to review the following conditions and restrictions: </p>
                <p dangerouslySetInnerHTML={{ __html: discountConditions }} />
            </Modal.Body>
            <Modal.Footer>
                {/* <button onClick={handleClose} className='me-3 btn btn-secondary'>Cancel</button> */}
                <Button onClick={handleClose}>OK</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default DicountConditions;