import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageBackground = () => {
  const location = useLocation();
  const darkPaths = ['/tournamentManagement', '/liveScore', '/certificateGenration', '/bibManagement', '/auctionBay', '/creativeMaking', '/contactUsPage'];

  useEffect(() => {
    document.body.style.backgroundColor = darkPaths.includes(location.pathname) ? '#000' : '#fff';
  }, [location.pathname]);

  return null;
};

export default PageBackground;
