import React from "react";
import { Link } from "react-router-dom";
const img_url = "../../../../images/";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    // behavior: "smooth" 
    // Optional: smooth scrolling behavior
  });
};

const Footer = ({ landingPage }) => {
  return (
    <div className={`footer-section ${landingPage ? 'landing-footer' : ''}`}>
      <div className="footer-Inner row">
        <div className="footer-logo col-lg-3 col-md-4 col-sm-12">
          <Link to="/" onClick={scrollToTop}>
            <img src={img_url + "ZemoLogoNeon.svg"} alt="Zemo" />
          </Link>
        </div>
        <div className="row col-lg-9 col-md-8 col-sm-12">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h4>Useful links</h4>
            <ul>
              <li>
                <Link onClick={scrollToTop} to="/termsConditions">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} to="/privacyPolicy" smooth={true} >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} to="/refundPolicy">
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} className="sendMessage" to="/contactUs">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h4>TOURNAMENTS</h4>
            <ul>
              <li>
                <Link onClick={scrollToTop} to="/browse-tournaments?upComing=1">
                  Upcoming
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} to="/browse-tournaments?onGoing=1">
                  Ongoing
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop}
                  to="/browse-tournaments?recentlyConclud=1"
                >
                  Concluded
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="footer-text">
              <h4>CONTACT</h4>
              <p>CIBA Vashi, 6th Floor, Agnel Technical Complex, Sector 9A Vashi, Navi Mumbai, Maharashtra 400703</p>
              <span class="footer-icon d-flex"><img class="mob-footer-icon" src="./images/mail_icon.svg" alt="Zemo" /><p class="mb-0"> support@zemo.co.in</p></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

