import React from "react";
import LandingPage from "./LandingPage";

const CreativeMakingPage = () => {

    const contentText = {
        paragraph1: "Because Every Game Deserves a Highlight!",
        paragraph2: "With our Creative Making feature, you’re not just managing a tournament; you’re creating moments worth sharing! Instantly generate stunning visuals for match updates, player spotlights, and tournament highlights—no design degree needed! With a few clicks, your tournament can look like it was covered by pros. Share your creations with fans, players, and social media for that extra wow factor. After all, if it’s not on Instagram, did it even happen?",
    };

    const features = [
        {
            title: "Instant Graphics",
            description: "Automatically create visually appealing posts formatch results, event announcements, and player highlights.",
            image: "instant-graphics.svg",
        },
        {
            title: "Dynamic Animations",
            description: "Bring your visuals to life with animated templatesfor match results, MVP announcements, or milestone celebrations, ensuring your content stands out on social media. ",
            image: "dynamic-animation.svg",
        },
        {
            title: "Customizable Templates",
            description: "Choose from a range of templates to fit your tournament’s vibe, whether it’s sleek andprofessional or bold and energetic.",
            image: "custom-template.svg",
        },
        {
            title: "Partner Branding",
            description: "Add sponsor logos to boost brand visibility and give your partners the exposure they’re looking for.",
            image: "partner-branding.svg",
        },
        {
            title: "Easy Sharing",
            description: "Download and share across all social media platforms in just one click—connect with fans wherever they are.",
            image: "easy-sharing.svg",
        },
        {
            title: "Real-Time Updates",
            description: "Generate and share creative content live as the tournament unfolds, keeping everyone in the loop and engaged.",
            image: "realtime-update.svg",
        },
        {
            title: "Schedule Creatives",
            description: "Create and share customized match schedules in visually engaging formats, making it easier for fans to follow along.",
            image: "schedule-creative.svg",
        },
        {
            title: "Theme Adaptability",
            description: "Adjust designs to align with seasonal or event-specific themes (e.g., festive colors, championshipvibes) to keep your content relevant and fresh.",
            image: "theme-adaptability.svg",
        },
    ];

    return (
        <>
            <LandingPage
                pageTitle={"CREATIVE MAKING FEATURES"}
                features={features}
                contentText={contentText}
            />
        </>
    )
}

export default CreativeMakingPage;