// import React from "react";

// const ContactUsPage = () => {
//     return (
//         <>
//         </>
//     )
// }

// export default ContactUsPage;
import React, { useState } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import ReactSelect from "react-select";
import Api from "../../../api/Api";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";

const ContactUsPage = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        company: "",
        contact: "",
        feature: [],
        message: "",
    });

    const [errors, setErrors] = useState({
        contact: "",
        email: "",
    });

    const queryOptions = [
        { value: "tournamentManagement", label: "Tournament Management" },
        { value: "scoringSystem", label: "Scoring System" },
        { value: "creativeMaking", label: "Creative Making" },
        { value: "certificateGeneration", label: "Certificate Generation" },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === "contact") {
            const isValidPhone = /^\d{10,}$/.test(value);
            setErrors({
                ...errors,
                contact: isValidPhone || value === "" ? "" : "Phone number must be at least 10 digits.",
            });
        }

        if (name === "email") {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            setErrors({
                ...errors,
                email: isValidEmail || value === "" ? "" : "Invalid email format.",
            });
        }
    };

    const handleSelectChange = (selectedOption) => {
        setFormData((prevData) => ({ ...prevData, feature: selectedOption }));
    };

    const isFormValid = () => {
        return (
            formData.fullName &&
            !errors.email &&
            !errors.contact &&
            formData.feature.length > 0 &&
            formData.message
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const param = {
            title: formData.feature.map((feature) => feature.label).join(", "),
            name: formData.fullName,
            email: formData.email,
            message: formData.message,
            company: formData.company,
            phone: formData.contact,
            requestFor: "featureAndPricing",
        };
        Api.CoreApi("contactUs", "POST", param)
            .then((response) => {
                if (response && response[0].accepted.length > 0) {
                    toast.success("Email sent successfully");
                } else {
                    toast.error("Email not sent");
                }
            })
            .catch(() => {
                toast.error("An error occurred while sending the email");
            });
    };

    return (
        <>
            <Container fluid>
                <div className="contact-form-page d-flex justify-content-center align-items-center">
                    <div className="form-container">
                        <h2 className="text-center">WE'RE HERE TO HELP!</h2>
                        <p className="text-center text-muted">
                            Have questions or need assistance? Fill out the form below & we’ll get back,
                            just not at lightning speed (but close enough)!
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="fullName">
                                        <Form.Label>Full Name<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your full name"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="company">
                                        <Form.Label>Company / Organization Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your company or organization name"
                                            name="company"
                                            value={formData.company}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email address"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="contact">
                                        <Form.Label>Contact Number<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter contact number"
                                            name="contact"
                                            value={formData.contact}
                                            onChange={handleChange}
                                            isInvalid={!!errors.contact}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contact}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="feature">
                                        <Form.Label>Select Feature<span className="text-danger">*</span></Form.Label>
                                        <ReactSelect
                                            options={queryOptions}
                                            placeholder="Select feature for the query"
                                            isClearable
                                            isMulti
                                            onChange={handleSelectChange}
                                            value={formData.feature}
                                            classNamePrefix="custom-select"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="message">
                                        <Form.Label>Message<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Details about the query"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end mt-2">
                                <Button
                                    type="submit"
                                    variant="success"
                                    disabled={!isFormValid()}
                                    className="px-4"
                                >
                                    Send Message
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Container>
            <Footer landingPage={true} />
        </>
    );
};

export default ContactUsPage;
