import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, ButtonGroup } from "react-bootstrap";
import Api from "../../../src/api/Api.js"
import { toast } from "react-toastify";

const ContactModal = ({ show, onHide }) => {
    const tournamentId = localStorage.getItem("selectedTournamentId");
    const parseTournamentDetails = localStorage.getItem("tournamentDetails");
    const tournamentDetails = JSON.parse(parseTournamentDetails);

    const [formData, setFormData] = useState({
        message: "",
        name: "",
        email: "",
        tournamentId: "",
        phone: "",
        type: "1",
    });
    const [formErrors, setFormErrors] = useState({
        name: false,
        email: false,
        type: false,
        message: false,
        phone: false,
    });

    const contactOrganizer = () => {
        const isValid = validateForm();
        if (isValid) {
            const params = {
                message: formData.message,
                name: formData.name,
                email: formData.email,
                tournamentId: tournamentId,
                type: formData.type,
                phone: formData.phone,
            };
            Api.call(`contactUs`, 'POST', params)
                .then((response) => {
                    if (response) {
                        setFormData({
                            message: "",
                            name: "",
                            email: "",
                            tournamentId: "",
                            phone: "",
                            type: "1",
                        });
                        onHide();
                        toast.success("Mail successfully sent.")
                    }
                })
        }
    }

    // validate form 
    const validateForm = () => {
        const errors = {
            name: !formData.name,
            email: !formData.email || !isValidEmail(formData.email),
            phone: !formData.phone,
            type: !formData.type,
            message: !formData.message,
        };
        setFormErrors(errors);
        return !Object.values(errors).some(Boolean);
    };

    // form fill
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // enquire type 
    const handleEnquiryTypeChange = (value) => {
        setFormData({
            ...formData,
            type: value,
        });
    };

    // email validation
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // copyToClipboard
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied")
    };

    return (
        <Modal className="contact-modal" show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Contact to {tournamentDetails?.organizerDetails?.name ? <> {tournamentDetails?.organizerDetails?.name}</> : 'Organizer'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <div className="optional-inputs">
                            <Col>
                                <div className="copyTournamentLink">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "images/organizerEmail.svg"} />
                                    </div>
                                    <input type="text" className="form-control" value={tournamentDetails?.organizerDetails?.email} readOnly />
                                    <button onClick={() => copyToClipboard(tournamentDetails?.organizerDetails?.email)}>
                                        <img src={process.env.PUBLIC_URL + "images/socialicons/copy-icon.svg"} alt="Copy Link" />
                                    </button>
                                </div>
                            </Col>
                            <Col>
                                <div className="copyTournamentLink">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "images/organizerphone.svg"} />
                                    </div>
                                    <input type="text" className="form-control" value={tournamentDetails?.organizerDetails?.phone} readOnly />
                                    <button onClick={() => copyToClipboard(tournamentDetails?.organizerDetails?.phone)}>
                                        <img src={process.env.PUBLIC_URL + "images/socialicons/copy-icon.svg"} alt="Copy Link" />
                                    </button>
                                </div>
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formName">
                                <Form.Label>Name<span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={formErrors.name}
                                />
                                <Form.Control.Feedback type="invalid">Please enter your name.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email<span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={formErrors.email}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formContact">
                                <Form.Label>Contact<span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter your Contact number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={formErrors.phone}
                                />
                                <Form.Control.Feedback type="invalid">Please enter your phone number.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formEnquiry">
                                <Form.Label>Enquiry<span style={{ color: "red" }}>*</span></Form.Label>
                                <ButtonGroup className="radioButtonOuter mb-3">
                                    <div className="radioButton">
                                        <input
                                            type="radio"
                                            id="tournament"
                                            name="enquiryType"
                                            value="1"
                                            checked={formData.type === "1"}
                                            onChange={() => handleEnquiryTypeChange("1")}
                                        />
                                        <label className={`btn btn-default ${formData.enquiryType === "1" ? 'btn-success' : ''}`} htmlFor="tournament">
                                            Tournament
                                        </label>
                                    </div>
                                    <div className="radioButton">
                                        <input
                                            type="radio"
                                            id="booking"
                                            name="enquiryType"
                                            value="2"
                                            checked={formData.type === "2"}
                                            onChange={() => handleEnquiryTypeChange("2")}
                                        />
                                        <label className={`btn btn-default ${formData.enquiryType === "2" ? 'btn-success' : ''}`} htmlFor="booking">
                                            My Booking
                                        </label>
                                    </div>
                                </ButtonGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formEnquiry">
                                <Form.Label>Message<span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    required
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    isInvalid={formErrors.message}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a message.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="cancel-btn" variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button className="send-message" variant="secondary" onClick={contactOrganizer}>
                    Send Message
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactModal;
