import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import OrganizerForm from "../../modal/OrganizerFormModal";

const img_url = "../../../images/";
const testimonials = [
    {
        text: "I had an amazing experience participating in tournaments on this platform. Super presentation of results, very innovative and very impressive.",
        name: "Vijay Kumar",
        sport: "Snooker Player",
        image: "../../../images/testimonialsLogo1.svg",
    },
    {
        text: "The player profiles and tournament details are user-friendly, making it easy for me to stay informed and engaged. Great scoring system.",
        name: "Player: Vishesh Sahgal",
        sport: "Snooker Player",
        image: "../../../images/testimonialsLogo2.svg",
    },
    {
        text: "From registration to matchday, the tournament was flawlessly organized. Super Scoring system by Zemo, presentable and neat.",
        name: "Ishpreet Singh Chadha",
        sport: "Snooker Player",
        image: "../../../images/testimonialsLogo3.svg",
    },
];

const LandingPage = ({ pageTitle, contentText, features }) => {
    const navigate = useNavigate();
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const contentRef = useRef(null);
    const [isPlaying1, setIsPlaying1] = useState(true);
    const [isPlaying2, setIsPlaying2] = useState(true);
    const [videoHeight, setVideoHeight] = useState("auto");
    const [modalOpen, setModalOpen] = useState(false);
    const [isUserLoggedIn, _isUserLoggedIn] = useState(false);
    const [isMuted1, setIsMuted1] = useState(true);
    const userDetails = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            _isUserLoggedIn(true);
        }
    }, [userDetails]);

    const onClose = () => {
        setModalOpen(false);
    }

    const handleInquiry = () => {
        setModalOpen(true);
    }

    const handlePlayPause = (videoRef, isPlaying, setIsPlaying) => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleMuteToggle = () => {
        setIsMuted1(!isMuted1);
        videoRef1.current.muted = !isMuted1;
    };

    const createTournament = () => {
        if (isUserLoggedIn && userDetails?.roleId === 3) {
            navigate("/createTournament");
        } else {
            navigate("/login");
            localStorage.setItem("landingPage", true);
        }
    }

    useEffect(() => {
        const updateVideoHeight = () => {
            const viewportWidth = window.innerWidth;
            const isSmallScreen = viewportWidth <= 992;

            if (contentRef.current) {
                const contentHeight = contentRef.current.offsetHeight;
                setVideoHeight(isSmallScreen ? "600px" : `${contentHeight}px`);
            }
        };

        updateVideoHeight();
        window.addEventListener("resize", updateVideoHeight);

        return () => window.removeEventListener("resize", updateVideoHeight);
    }, [videoHeight]);

    return (
        <>
            <div className="tournamentManagement pageContainer">
                <Container fluid>
                    <h2>{pageTitle}</h2>
                    <section className="managementTab">
                        <div className="row justify-content-between align-items-stretch">
                            {/* Video Section */}
                            <div className="col-lg-4 col-md-6 col-sm-12 pe-lg-4">
                                <div className="tournamentVideoWrapper" style={{ height: videoHeight }}>
                                    <video
                                        width="100%"
                                        height="100%"
                                        ref={videoRef1}
                                        controls={false}
                                        muted={isMuted1}
                                        autoPlay
                                        loop
                                        playsInline
                                    >
                                        <source src="../../../video/managementBanner.mp4" type="video/mp4" />
                                    </video>
                                    <button
                                        className="muteUnmuteButton"
                                        onClick={handleMuteToggle}
                                    >
                                        {isMuted1 ? (
                                            <i className="unmuteIcon">
                                                <img src={img_url + "unmute.png"} alt="Unmute" />
                                            </i>
                                        ) : (
                                            <i className="muteIcon">
                                                <img src={img_url + "mute.png"} alt="Mute" />
                                            </i>
                                        )}
                                    </button>
                                    <button className="playPauseButton" onClick={() => handlePlayPause(videoRef1, isPlaying1, setIsPlaying1)}>
                                        {isPlaying1 ? (
                                            <i className="paueIcon"><img src={img_url + "pause.png"} /></i>
                                        ) : (
                                            <i className="playIcon"><img src={img_url + "play-buttton.png"} /></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                            {/* Content Section */}
                            <div className="col-lg-8 col-md-6 col-sm-12 ps-lg-4 mt-4 mt-lg-0">
                                <div className="journyTab" ref={contentRef}>
                                    <div className="features-container">
                                        {features.map((feature, index) => {
                                            const words = feature.title.split(" ");
                                            const firstLine = words.slice(0, words.length === 2 ? 1 : 2).join(" ");
                                            const secondLine = words.slice(words.length === 2 ? 1 : 2).join(" ");
                                            return (
                                                <div key={index} className="row feature-row m-0 align-items-start">
                                                    {/* Title */}
                                                    <div className="col-12 col-md-5 d-flex align-items-center gap-4 mb-2 mb-md-0">
                                                        <div className="featureIcon ml-2">
                                                            <img src={img_url + feature.image} alt="Feature Icon" />
                                                        </div>
                                                        <div className="feature-title">
                                                            <span className="first-word">{firstLine}</span>
                                                            {secondLine && <span className="second-word">{secondLine}</span>}
                                                        </div>
                                                    </div>
                                                    {/* Description */}
                                                    <div className="col-12 col-md-7 feature-description text-md-left mt-2 mt-md-0">
                                                        {feature.description}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="pageContent">
                        <div class="row justify-content-center px-5 pt-5">
                            <h2>{contentText.paragraph1}</h2>
                            {contentText.paragraph2 && <p>{contentText.paragraph2}</p>}
                            {contentText.paragraph3 && <p>{contentText.paragraph3}</p>}
                            {contentText.paragraph4 && <p>{contentText.paragraph4}</p>}
                            {contentText.paragraph5 && <p>{contentText.paragraph5}</p>}
                        </div>
                    </div>
                    <div className="hostSection">
                        <div className="hostDetails row m-0">
                            <div className="col-12 col-md-3 d-none d-md-flex justify-content-md-start p-0">
                                <div className="zemoLogo">
                                    <img src={img_url + "zemo-black.svg"} alt="Zemo Logo" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center text-center mt-3 mt-md-0">
                                <div className="tournamentText">
                                    <h2>READY TO HOST YOUR TOURNAMENT?</h2>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 d-flex align-items-center justify-content-center mt-3 mt-md-0">
                                <div className="createBtn">
                                    <button className="btn btn-primary px-4 py-2 mb-3 mb-md-0" onClick={createTournament}>
                                        <span className="d-block d-md-none">CREATE YOUR TOURNAMENT</span>
                                        <span className="d-none d-md-block">CREATE TOURNAMENT</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="tutorialsContainer">
                    <h2>TUTORIALS</h2>
                    <div className="tutorialsVideoWrapper">
                        <video
                            width="100%"
                            height="100%"
                            ref={videoRef2}
                            controls={false}
                            muted
                            autoPlay
                            loop
                            playsInline
                        >
                            <source src="../../../video/managementBanner.mp4" type="video/mp4" />
                        </video>
                        <button className="playPauseButton" onClick={() => handlePlayPause(videoRef2, isPlaying2, setIsPlaying2)}>
                            {isPlaying2 ? (
                                <i className="pauseIcon"><img src={img_url + "pause.png"} /></i>
                            ) : (
                                <i className="playIcon"><img src={img_url + "play-buttton.png"} /></i>
                            )}
                        </button>
                    </div>
            </div> */}
                    <div className="testimonials">
                        <h2>TESTIMONIALS</h2>
                        <section className="testimonials-section">
                            <div className="row p-4">
                                {testimonials.map((testimonial, index) => (
                                    <div className={`col-lg-4 col-md-6 mb-lg-0 ${index === testimonials.length - 1 ? '' : 'mb-4'}`} key={index}>
                                        <div className="testimonial-card d-flex flex-column position-relative shadow-sm">
                                            <div className="row flex-grow-1">
                                                <div className="col-9 d-flex flex-column p-3 p-md-5 text-center">
                                                    <div className="testimonial-text-wrapper">
                                                        <p className="testimonial-text mb-3">{testimonial.text}</p>
                                                        <h5 className="testimonial-name mb-2">{testimonial.name}</h5>
                                                        <span className="sport-name mb-0">{testimonial.sport}</span>
                                                    </div>
                                                </div>
                                                <div className="col-3 d-flex justify-content-end align-items-end">
                                                    <img
                                                        src={testimonial.image}
                                                        alt={testimonial.name}
                                                        className="testimonial-image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </Container>
            </div>
            <div className="knowing-more-container">
                <div className="knowing-more">
                    <div className="hostingtournament" style={{ backgroundImage: "url('../../../../images/hostBG.jpg')", }}>
                        <div className="bgColor">
                            <div className="hostOuter">
                                <h2>INTERESTED IN KNOWING MORE?</h2>
                                <p>Fill out the form below and our team will reach out to you shortly!</p>
                                <div className="hostCreate">
                                    <button onClick={handleInquiry} className="btn green-btn-primary">GET IN TOUCH</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer landingPage={true} />
            <OrganizerForm
                showModal={modalOpen}
                onClose={onClose}
            />
        </>
    )
}

export default LandingPage;