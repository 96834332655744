import React from "react";
import LandingPage from "./LandingPage";

const CertificateGenrationPage = () => {

    const contentText = {
        paragraph1: "Because Every Player Deserves Recognition!",
        paragraph2: "Celebrate every player, coach and contributor with ease! With our Certificate Generation feature, you can instantly create professional, customized certificates for participants, winners, or volunteers - no design skills required! Choose from a range of templates to match your event’s style and personalize each certificate with names, achievements, and even your tournament logo. From participation acknowledgments to victory milestones, make sure everyone leaves with a keepsake to remember. It’s hassle-free, fast and perfect for adding that extra touch of professionalism to your tournament. The best part? You can create unlimited certificates instantly!",
        paragraph3: "Turn every tournament into a memorable experience with Certificate Generation - because recognition matters! ",
    };

    const features = [
        {
            title: "Easy Certificate Creation",
            description: "Generate certificates in just a few clicks - simple, fast, and efficient.",
            image: "easycertificate-creation.svg",
        },
        {
            title: "Customizable Templates",
            description: "Choose from a variety of designs to match thelook and feel of your tournament.",
            image: "custom-template.svg",
        },
        {
            title: "Personalized Details",
            description: "Add names, roles, and achievements to make each certificate special and memorable.",
            image: "personalized-details.svg",
        },
        {
            title: "Batch Processing",
            description: "Create multiple certificates at once for large groups, saving time and effort.",
            image: "batch-processing.svg",
        },
        {
            title: "Participation Acknowledgments",
            description: "Recognize every participant, from players to volunteers, with a unique certificate.",
            image: "participation-acknowledgments.svg",
        },
        {
            title: "Branding Options",
            description: "Include your tournament logo and sponsor logos to enhance brand visibility and create acohesive look.",
            image: "branding-options.svg",
        },
        {
            title: "Instant Download & Share",
            description: "Download your certificates instantly and share them digitally or in print for quick distribution.",
            image: "instant-download-share.svg",
        },
        {
            title: "Certificate Archive",
            description: "Store past certificates securely for quick retrieval and reprints.",
            image: "certificate-archive.svg",
        },
        {
            title: "Eco-Friendly Options",
            description: "Offer digital-only certificates to reduce paperusage and promote sustainability.",
            image: "ecofriendly-options.svg",
        },
    ];

    return (
        <>
            <LandingPage
                pageTitle={"CERTIFICATE GENERATION FEATURES"}
                features={features}
                contentText={contentText}
            />
        </>
    )
}

export default CertificateGenrationPage;